import {useCallback, useMemo} from 'react';
import actions from 'ducks/actions';

import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {SEARCH_MAX_ITEM_SIZE, SEARCH_PAGING_SIZE} from 'ducks/search/slice';
import {ESearchCollectionType, TBusLineItem, TBusStationItem} from 'ducks/search/types';
import {TSearchPoi} from 'types/App';
import {ESearchLocationType, ESortOption} from 'types/Search';
import {EListMode} from 'types/ListDrawer';
import {TActionId} from 'types/Log';

import {EFilterActionId, EPageType, ETNowActionId, NATIONAL_VALUE} from 'constant/Log';
import {EAdCode, EAdType} from 'constant/Ads';

import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import usePageInfo from 'hooks/usePageInfo';
import useSearchResult from './useSearchResult';

import LogManager from 'utils/logManager';

type TOption = {
  includeTicketId: boolean;
};

const MAP_VIEW = {
  [EListMode.BOTTOM]: 0,
  [EListMode.CENTER]: 1,
  [EListMode.TOP]: 2,
};

const SEARCH_RESULT_DATA_KEY_MAP: Record<ESearchCollectionType, string> = {
  [ESearchCollectionType.POI]: 'pkey',
  [ESearchCollectionType.BUS_LINE]: 'busLineId',
  [ESearchCollectionType.BUS_STATION]: 'busStationId',
};
const SEARCH_RESULT_KEY_MAP: Record<ESearchCollectionType, string> = {
  [ESearchCollectionType.POI]: 'pkey',
  [ESearchCollectionType.BUS_LINE]: 'bus_line_id',
  [ESearchCollectionType.BUS_STATION]: 'bus_station_id',
};

enum EOnOff {
  ON = 1,
  OFF = 0,
}

const useLogger = () => {
  const {
    drawerMode,
    ticketId,
    accessKey,
    userKey,
    euk,
    device,
    sessionId,
    searchSessionKey,
    radius,
    currentAddressName,
    displayAddressName,
    fixedRadius,
    center,
    userPosition,
    filter,
    rdTnow,
    rdSearch,
    rdPlace,
    searchQueryRefreshKey,
    bypassCustomItems,
    collectionType,
  } = useAppSelector((state) => ({
    drawerMode: state.userInteraction.drawerMode,
    accessKey: state.userInfo.sessionId,
    userKey: state.userInfo.userKey,
    euk: state.userInfo.euk,
    device: state.userInfo.device,
    sessionId: state.userInfo.sessionId,
    searchSessionKey: state.userInfo.searchSessionKey,
    ticketId: state.userInfo.sessionKey,
    radius: state.tNow.baseSpot.radius,
    currentAddressName: state.tNow.baseSpot.currentAddressName,
    displayAddressName: state.tNow.baseSpot.displayAddressName,
    fixedRadius: state.tNow.baseSpot.fixedRadius,
    center: state.map.lastCachedCenter,
    userPosition: state.map.userPosition,
    filter: state.place.placeQuery,
    rdTnow: state.tNow.result,
    rdSearch: state.search,
    rdPlace: state.place,
    searchQueryRefreshKey: state.log.searchQueryRefreshKey,
    isDiscountOn: state.place.tmapParkYn === 'Y',
    bypassCustomItems: state.log.bypassCustomItems || {},
    collectionType: state.search.data.collectionType,
  }));

  const dispatch = useAppDispatch();
  const {clickedFilter: searchRecommendFilter} = useSearchResult();
  const {queries} = useParseQueryLocation();
  const mapview = useMemo(() => drawerMode && MAP_VIEW[drawerMode], [drawerMode]);
  const currentFilter = useMemo(() => {
    return filter || queries?.type;
  }, [filter, queries]);
  const {isSearchMainPage, isSearchResultPage, isPlaceMainPage, isPlaceCategoryPage} =
    usePageInfo();

  const searchCallId = useMemo(
    () =>
      isSearchResultPage && searchQueryRefreshKey
        ? `${accessKey}_${searchQueryRefreshKey}`
        : ticketId,
    [ticketId, accessKey, isSearchResultPage, searchQueryRefreshKey]
  );

  const logPageType = useMemo(() => {
    if (isSearchResultPage || isSearchMainPage) {
      return EPageType.SEARCH;
    }

    if (isPlaceCategoryPage) {
      return EPageType.PLACE_CATEGORY;
    }

    if (isPlaceMainPage) {
      return EPageType.PLACE_MAIN;
    }
  }, [isSearchResultPage, isSearchMainPage, isPlaceMainPage, isPlaceCategoryPage]);

  const indexRadius = useMemo(() => (fixedRadius ? EOnOff.ON : EOnOff.OFF), [fixedRadius]);

  const currentData = useMemo(
    () => ({
      radius: radius === Infinity ? NATIONAL_VALUE : radius,
      region: currentAddressName,
      coord: center ? [center.lon, center.lat] : [userPosition?.lon, userPosition?.lat],
      ...(displayAddressName && {zone: displayAddressName}),
    }),
    [currentAddressName, radius, center, userPosition, displayAddressName]
  );

  const sendClickLog = useCallback(
    (
      actionId: TActionId,
      custom = {},
      option: TOption = {includeTicketId: !!isSearchResultPage}
    ) => {
      if (logPageType) {
        LogManager.sendClickLog(logPageType, actionId, {
          ...(isSearchResultPage && {
            tab: collectionType,
          }),
          ...(bypassCustomItems.click || {}),
          ...custom,
          ...(option.includeTicketId && {
            search_call_id: searchCallId,
            search_session_id: searchSessionKey,
            search_recommend: rdSearch.data?.ltrYn,
          }),
        });
      }
    },
    [
      logPageType,
      searchCallId,
      searchSessionKey,
      isSearchResultPage,
      collectionType,
      bypassCustomItems.click,
      rdSearch.data?.ltrYn,
    ]
  );

  const sendApp = useCallback(
    (
      actionId: TActionId,
      custom = {},
      option: TOption = {includeTicketId: !!isSearchResultPage}
    ) => {
      LogManager.sendApp(actionId, {
        ...(bypassCustomItems.app || {}),
        ...custom,
        ...(option.includeTicketId && {
          search_call_id: searchCallId,
          search_session_id: searchSessionKey,
        }),
      });
    },
    [searchCallId, searchSessionKey, bypassCustomItems.app, isSearchResultPage]
  );

  const sendExpose = useCallback(
    (custom = {}, option: TOption = {includeTicketId: false}) => {
      if (logPageType) {
        LogManager.sendExpose(logPageType, {
          ...(isSearchResultPage && {
            tab: collectionType,
          }),
          ...(bypassCustomItems.expose || {}),
          ...custom,
          ...(option.includeTicketId && {
            search_call_id: searchCallId,
            search_session_id: searchSessionKey,
          }),
        });
      }
    },
    [
      searchCallId,
      logPageType,
      searchSessionKey,
      isSearchResultPage,
      collectionType,
      bypassCustomItems.expose,
    ]
  );

  const sendPageView = useCallback(
    (custom = {}, option: TOption = {includeTicketId: !!isSearchResultPage}) => {
      if (logPageType) {
        LogManager.sendPageView(logPageType, {
          ...(bypassCustomItems.expose || {}),
          ...custom,
          ...(option.includeTicketId && {
            search_call_id: searchCallId,
            search_session_id: searchSessionKey,
          }),
        });
      }
    },
    [bypassCustomItems, searchCallId, searchSessionKey, logPageType, isSearchResultPage]
  );

  // 티지금, 주변
  const sendPlacePageLog = useCallback(
    (search_query?: string) => {
      sendPageView({
        search_query,
        list_num: rdPlace.data.totalCount,
        coord: currentData.coord,
      });
    },
    [sendPageView, rdPlace, currentData]
  );

  const sendPlaceResultLog = useCallback(
    (search_query?: string) => {
      const placeList = rdPlace.data.list;

      sendClickLog(
        'impression.poilist',
        {
          pkey: placeList.map((v) => v.pkey),
          page: rdPlace.data.currentPage,
          search_query,
          list_num: rdPlace.data.totalCount,
          coord: currentData.coord,
        },
        {
          includeTicketId: true,
        }
      );
    },
    [sendClickLog, rdPlace, currentData]
  );

  const sendTNowResultLog = useCallback(() => {
    const tnowList = rdTnow.data.list;
    const params = tnowList.reduce(
      (obj, v, i) => ({
        pkey: [...obj.pkey, v.pkey],
        list_seq: [...obj.list_seq, i + 1],
        index_feature: [...obj.index_feature, v.headingForScore ? EOnOff.ON : EOnOff.OFF],
        obj_cnt: [...obj.obj_cnt, v.headingForScore],
        index_image: [
          ...obj.index_image,
          (v.imageInfo || []).filter(
            (image) => !((v.imageInfo || []).length === 1 && image.source === 'CATEGORY')
          ).length > 0
            ? EOnOff.ON
            : EOnOff.OFF,
        ],
      }),
      {
        pkey: [] as string[],
        list_seq: [] as number[],
        index_feature: [] as EOnOff[],
        obj_cnt: [] as number[],
        index_image: [] as number[],
      }
    );

    const param = {
      ...currentData,
      ...params,
      index_radius: indexRadius,
      list_num: rdTnow.data.totalCount,
      page: rdTnow.data.currentPage,
      time_key: rdTnow.data.firstRequestTime,
    };

    sendClickLog('impression.poilist', param);
  }, [rdTnow, currentData, indexRadius, sendClickLog]);

  const sendClickLogWithMapView = useCallback(
    (actionId: TActionId, custom = {}, option?: TOption) => {
      sendClickLog(
        actionId,
        {
          mapview,
          ...custom,
        },
        option
      );
    },
    [mapview, sendClickLog]
  );

  const sendTNowItemClickLog = useCallback(
    (actionId: TActionId, custom = {}) => {
      sendClickLogWithMapView(
        actionId,
        {
          ...currentData,
          index_radius: indexRadius,
          // index_feature : 가고있어요 대상
          // 이 처리는 API에서 하므로 5대 미만은 모두 0으로 내려옴
          index_feature: custom.obj_cnt ? EOnOff.ON : EOnOff.OFF,
          ...custom,
        },
        {includeTicketId: true}
      );
    },
    [sendClickLogWithMapView, indexRadius, currentData]
  );

  const sendRankingItemClickLog = useCallback(
    (actionId: TActionId, custom = {}) => {
      sendClickLog(
        actionId,
        {
          euk,
          ...custom,
        },
        {includeTicketId: true}
      );
    },
    [sendClickLog, euk]
  );

  const sendNearbyItemClickLog = useCallback(
    (actionId: TActionId, custom = {}) => {
      sendClickLogWithMapView(
        actionId,
        {
          search_query: currentFilter,
          ...custom,
        },
        {includeTicketId: true}
      );
    },
    [sendClickLogWithMapView, currentFilter]
  );

  const sendExtendButtonLog = useCallback(() => {
    sendClickLogWithMapView(
      ETNowActionId.EXTEND_BUTTON,
      {
        ...currentData,
      },
      {includeTicketId: true}
    );
  }, [sendClickLogWithMapView, currentData]);

  const sendFilterClickLog = useCallback(
    (
      actionId: TActionId,
      {filter1, filter2, filter3, ...custom} = {filter1: null, filter2: null, filter3: null}
    ) => {
      const query = queries?.type;

      const value = {
        [EFilterActionId.FILTER_ITEM_1]: filter1 || query,
        [EFilterActionId.FILTER_ITEM_2]: filter2,
        [EFilterActionId.FILTER_ITEM_3]: filter3,
      }[actionId];

      sendClickLogWithMapView(
        actionId,
        {
          option: value || '',
          ...custom,
        },
        {includeTicketId: true}
      );
    },
    [sendClickLogWithMapView, queries]
  );

  const sendSortClickLog = useCallback(
    (sort: ESortOption) => {
      const actionIdMap: Record<ESortOption, EFilterActionId> = {
        [ESortOption.SCORE]: EFilterActionId.SCORE,
        [ESortOption.DEST_SCORE]: EFilterActionId.SCORE,
        [ESortOption.DISTANCE]: EFilterActionId.DISTANCE,
        [ESortOption.PRICE]: EFilterActionId.PRICE,
        [ESortOption.SCORE_WEEK]: EFilterActionId.SCORE_WEEK,
      };

      const actionId = actionIdMap[sort];

      sendFilterClickLog(actionId, {search_query: currentFilter});
    },
    [sendFilterClickLog, currentFilter]
  );

  const sendSearchPageLog = useCallback(
    ({search_query, index, ...rest}) => {
      sendExpose(
        {
          search_query,
          // list_num: rdSearch.data.totalCount,
          // 0 : 내위치 중심, 1: 지도 중심
          search_location_index: rdSearch.locationType === ESearchLocationType.USER ? 0 : 1,
          index,
          // mapview,
          search_recommend: rdSearch.data?.ltrYn,
          ...rest,
        },
        {includeTicketId: true}
      );
    },
    [sendExpose, rdSearch]
  );

  const searchListNum = useMemo(
    () => Math.min(rdSearch.data.totalCount, SEARCH_MAX_ITEM_SIZE),
    [rdSearch.data.totalCount]
  );

  const sendSearchResultLog = useCallback(
    ({search_query, search_type, guide_search_type, index}) => {
      const sliceIndex =
        rdSearch.nowPage === 1 ? undefined : (rdSearch.nowPage - 1) * SEARCH_PAGING_SIZE - 1;
      const baseList = rdSearch.searchList
        .reduce((arr, v) => {
          // @ts-ignore
          return [...arr, v, ...(v.groupSubList || [])];
        }, [] as any)
        .slice(sliceIndex);

      const dataList = baseList.map((s) => s[SEARCH_RESULT_DATA_KEY_MAP[collectionType]]);

      sendClickLogWithMapView(
        'impression.poilist',
        {
          search_query,
          coord: center ? [center.lon, center.lat] : [userPosition?.lon, userPosition?.lat],
          list_num: searchListNum,
          page: rdSearch.nowPage,
          //  0 (현위치 중심), 1 (지도 중심)
          search_location_index: rdSearch.locationType === ESearchLocationType.MAP ? 1 : 0,
          search_type,
          guide_search_type,
          //  0 (resume - 상세나 길찾기 했다가 돌아왔을 때), 1 (첫진입 / 재검색)
          index,
          // list_seq: baseList.map((_, i) => rdSearch.nowPage - 1 + i + 1),
          // 1: 이미지 있음 0 이미지 없음
          // index_image: baseList.map((s) => ((s.imageInfo || []).length ? 1 : 0)),
          // 1: 지도 중심 0: 현위치 중심
          filter_name: searchRecommendFilter.join(','),
          // 추천 검색 적용 필터
          ...{[SEARCH_RESULT_KEY_MAP[collectionType]]: dataList},
        },
        {includeTicketId: true}
      );
    },
    [
      sendClickLogWithMapView,
      searchListNum,
      rdSearch,
      center,
      userPosition,
      collectionType,
      searchRecommendFilter,
    ]
  );

  const sendSearchNoResultLog = useCallback(
    ({search_query, index}) => {
      sendClickLog(
        'impression.poilist',
        {
          search_query,
          list_num: searchListNum,
          search_location_index: rdSearch.locationType === ESearchLocationType.MAP ? 1 : 0,
          // 0 (resume - 상세나 길찾기 했다가 돌아왔을 때), 1 (첫진입 / 재검색)
          index,
          filter_name: searchRecommendFilter.join(','),
        },
        {includeTicketId: true}
      );
    },
    [searchListNum, rdSearch.locationType, sendClickLog, searchRecommendFilter]
  );

  const sendSearchRecentRecommendLog = useCallback(
    ({search_radius, list_num, coord, pkey, cnt_type, obj_cnt}) => {
      sendClickLog(
        'impression.tnow_recommendation',
        {
          radius: search_radius,
          list_num,
          coord,
          pkey,
          cnt_type,
          obj_cnt,
        },
        {includeTicketId: true}
      );
    },
    [sendClickLog]
  );

  const sendSearchItemClickLog = useCallback(
    (actionId: TActionId, {list_seq, pkey, tags = []}) => {
      sendClickLogWithMapView(
        actionId,
        {
          search_query: queries.searchQuery,
          list_num: searchListNum,
          list_seq,
          pkey,
          tag: tags,
        },
        {includeTicketId: true}
      );
    },
    [sendClickLogWithMapView, searchListNum, queries.searchQuery]
  );

  const sendSearchItemAdClickLog = useCallback(
    (
      actionId: TActionId,
      params: {
        ad_source: EAdType;
        url: Nullable<string>;
      }
    ) => {
      sendClickLog(
        actionId,
        {
          index: EAdCode.SEARCH_RESULT_LIST,
          ad_source: params.ad_source,
          url: params.url,
          search_query: queries.searchQuery,
        },
        {includeTicketId: true}
      );
    },
    [sendClickLog, queries]
  );

  const sendSearchItemAdAppLog = useCallback(
    (params: {ad_source: EAdType}) => {
      sendApp('', {
        index: EAdCode.SEARCH_RESULT_LIST,
        ad_source: params.ad_source,
        search_query: queries.searchQuery,
        list_num: searchListNum,
      });
    },
    [sendApp, queries, searchListNum]
  );

  const sendQuickLinkClickLog = useCallback(
    ({search_query}) => {
      sendClickLog(
        'tap.near_poi_category',
        {
          search_query,
          coord: currentData.coord,
        },
        {includeTicketId: true}
      );
    },
    [currentData, sendClickLog]
  );

  const sendLogWithSearchQuery = useCallback(
    (actionId: TActionId, params = {}) => {
      sendClickLog(
        actionId,
        {
          search_query: queries.searchQuery,
          list_num: searchListNum,
          ...params,
        },
        {
          includeTicketId: true,
        }
      );
    },
    [sendClickLog, queries, searchListNum]
  );

  const sendSearchResultItemClickLog = useCallback(
    (actionId: TActionId, {index, tags, subIdx}) => {
      const item = rdSearch.searchList?.[index];
      const bus_line_id = (item as TBusLineItem)?.busLineId;
      const bus_station_id = (item as TBusStationItem)?.busStationId;
      const categorycode = (item as TSearchPoi)?.mainCategory;
      const catchTableWaitingResponse = (item as TSearchPoi)?.special?.catchTableWaitingResponse;
      const {
        isAvailableReservation,
        isAvailableWaiting,
        isAvailableOnlineWaiting,
        unit,
        onlineWaitingDisableReason,
      } = catchTableWaitingResponse || {};
      const pkey =
        subIdx === undefined
          ? item.pkey
          : (item as TSearchPoi)?.groupSubList?.[subIdx]?.pkey ?? item.pkey;

      sendClickLogWithMapView(
        actionId,
        {
          search_query: queries.searchQuery,
          list_num: searchListNum,
          list_seq: index,
          page: rdSearch.nowPage,
          pkey,
          tag: (tags || []).map((t) => t.type).join(', '),
          ...(bus_line_id && {bus_line_id}),
          ...(bus_station_id && {bus_station_id}),
          ...(categorycode && {categorycode}),
          is_catch: !!catchTableWaitingResponse,
          is_reserve: !!isAvailableReservation,
          is_waiting: !!isAvailableWaiting,
          ...(!!isAvailableWaiting && {
            waiting_available: isAvailableOnlineWaiting,
            waiting_count: unit?.count,
            waiting_not_available_reason: onlineWaitingDisableReason,
          }),
          is_poi_ad: (item as TSearchPoi)?.special?.advertiseInfo?.isPoiAdvertiseYn,
          euk,
          filter_name: searchRecommendFilter.join(','),
        },
        {
          includeTicketId: true,
        }
      );
    },
    [rdSearch, sendClickLogWithMapView, queries, searchListNum, euk, searchRecommendFilter]
  );

  const sendCouponClickLog = useCallback(
    ({idx, tags}) => {
      const pkey = isSearchResultPage ? rdSearch.searchList[idx].pkey : rdPlace.data.list[idx].pkey;
      const page = isSearchResultPage ? rdSearch.nowPage : rdPlace.data.currentPage;

      sendClickLogWithMapView(
        'list_tap.poi_coupon',
        {
          search_query: queries.searchQuery,
          list_num: isSearchResultPage ? searchListNum : rdPlace.data.totalCount,
          list_seq: idx,
          pkey,
          tag: tags?.map((tag) => tag.type).join(', ') || '',
          page,
        },
        {includeTicketId: true}
      );
    },
    [
      sendClickLogWithMapView,
      queries,
      rdSearch.searchList,
      rdPlace.data.list,
      isSearchResultPage,
      searchListNum,
      rdSearch.nowPage,
      rdPlace.data.currentPage,
      rdPlace.data.totalCount,
    ]
  );

  const sendReservationClickLog = useCallback(
    (actionId: TActionId, {index, tags}) => {
      const item = isSearchResultPage
        ? (rdSearch.searchList[index] as TSearchPoi)
        : rdPlace.data.list[index];
      const catchTableWaitingResponse = item?.special?.catchTableWaitingResponse;
      const {
        isAvailableReservation,
        isAvailableWaiting,
        isAvailableOnlineWaiting,
        unit,
        onlineWaitingDisableReason,
      } = catchTableWaitingResponse || {};

      sendClickLogWithMapView(actionId, {
        search_query: queries.searchQuery,
        list_num: searchListNum,
        list_seq: index,
        pkey: item?.pkey,
        search_call_id: searchCallId,
        tag: (tags || []).map((t) => t.type).join(', '),
        is_catch: !!catchTableWaitingResponse,
        is_reserve: !!isAvailableReservation,
        is_waiting: !!isAvailableWaiting,
        euk,

        // waiting button
        ...(!!isAvailableWaiting && {
          waiting_available: isAvailableOnlineWaiting,
          waiting_count: unit?.count,
          waiting_not_available_reason: onlineWaitingDisableReason,
        }),
      });
    },
    [
      sendClickLogWithMapView,
      queries,
      searchListNum,
      euk,
      searchCallId,
      rdSearch,
      rdPlace,
      isSearchResultPage,
    ]
  );

  const initialize = useCallback(
    ({referrer, pageId, pageType}) => {
      LogManager.init({
        sessionId,
        accessKey,
        sessionKey: ticketId,
        userKey,
        deviceId: device.deviceId,
        carrierName: device.carrierName,
        pageId,
        pageType,
        referrer,
      });
      dispatch(actions.log.setInitialize(true));
    },
    [accessKey]
  );

  return {
    initialize,
    sendApp,
    sendExpose,
    sendClickLog,
    sendSortClickLog,
    sendFilterClickLog,
    sendClickLogWithMapView,
    sendTNowItemClickLog,
    sendRankingItemClickLog,
    sendNearbyItemClickLog,
    sendExtendButtonLog,
    sendTNowResultLog,
    sendSearchPageLog,
    sendSearchResultLog,
    sendSearchItemClickLog,
    sendSearchItemAdClickLog,
    sendSearchItemAdAppLog,
    sendPlacePageLog,
    sendPlaceResultLog,
    sendSearchNoResultLog,
    sendQuickLinkClickLog,
    sendSearchRecentRecommendLog,
    sendLogWithSearchQuery,
    sendSearchResultItemClickLog,
    sendCouponClickLog,
    sendReservationClickLog,
  };
};

export default useLogger;
