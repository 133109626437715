// import ImageLoader from 'components/ImageLoader';
import {TTPlaceCurationType2} from 'ducks/remote/type';
import {moveByLandingType} from 'utils/url';
import usePlaceHome from 'hooks/usePlaceHome';
import Thumbnail from 'components/Thumbnail';
import {IcLineBetween} from 'components/@tmds/icons/v1.2/IcLineBetween';

import s from 'styles/components/tplacehome/TPlaceCurationType2.module.scss';

type TProps = {
  item: TTPlaceCurationType2;
  contentIndex: number;
};

const TPlaceCurationType2 = ({item, contentIndex}: TProps) => {
  const placehomeHook = usePlaceHome();
  return (
    <div className={s.wrap}>
      <div className={s.title_wrap}>
        <div className={s.title_image_wrap}>
          <Thumbnail
            imgSrc={item.thumbnailUrl}
            alt={item.title}
            className={s.bg}
            width="100%"
            height="100%"
            borderColor="none"
          />
          {/* <ImageLoader src={item.thumbnailUrl} alt={item.title} className={s.bg} /> */}
          <div className={s.gradient} />
        </div>

        <div className={s.title}>
          <h4 dangerouslySetInnerHTML={{__html: item.title}} />
          <span className={s.desc} dangerouslySetInnerHTML={{__html: item.desc}} />
        </div>
      </div>
      {item.list.length > 0 && (
        <ul className={s.list_wrap}>
          {item.list.map((v, i) => (
            <li
              key={i}
              className={s.item}
              onClick={() => {
                placehomeHook.sendCurrationTapEvent(item.type, item.title, i, contentIndex);
                moveByLandingType(v.landingType, v.landingUrl);
              }}
            >
              <Thumbnail
                imgSrc={v.thumbnailUrl}
                alt={v.poiName}
                className={s.image}
                width={75}
                height={60}
              />
              {/* <ImageLoader src={v.thumbnailUrl} alt={v.poiName} className={s.image} /> */}
              <div className={s.poi_wrap}>
                <span className={s.poi_name}>{v.poiName}</span>

                <div className={s.detail_wrap}>
                  <span className={s.address}>{v.address}</span>
                  {/* TODO: color 수정 */}
                  <IcLineBetween color="#E0E2E8" width={10} height={10} />
                  <span className={s.category}>{v.category}</span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TPlaceCurationType2;
