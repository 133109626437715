import Thumbnail from 'components/Thumbnail';
import {TTPlaceCurationType1} from 'ducks/remote/type';
import {moveByLandingType} from 'utils/url';
import usePlaceHome from 'hooks/usePlaceHome';

import s from 'styles/components/tplacehome/TPlaceCurationType1.module.scss';

type TProps = {
  item: TTPlaceCurationType1;
  contentIndex: number;
};

const TPlaceCurationType1 = ({item, contentIndex}: TProps) => {
  const placehomeHook = usePlaceHome();
  return (
    <div className={s.wrap}>
      <h4 dangerouslySetInnerHTML={{__html: item.title}} />
      {item.list.length > 0 && (
        <ul className={s.list} data-flicking-ignore="true">
          {item.list.map((v, i) => (
            <li
              key={i}
              className={s.item}
              onClick={() => {
                placehomeHook.sendCurrationTapEvent(item.type, item.title, i, contentIndex);
                moveByLandingType(v.landingType, v.landingUrl);
              }}
            >
              <Thumbnail imgSrc={v.thumbnailUrl} alt={v.poiName} className={s.image} />
              <span className={s.poi_name}>{v.poiName}</span>
              <span className={s.category}>{v.category}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TPlaceCurationType1;
