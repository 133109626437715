import {useAppSelector} from 'ducks/hooks';
import {useCallback} from 'react';
import s from 'styles/components/WeatherInfo.module.scss';
import {WeatherWidget} from '@tmap-web-lib/tmap-weather';
import {moveToUrl} from 'utils/url';
import useLogger from 'hooks/useLogger';
import {TActionId} from 'types/Log';
import classNames from 'classnames';
import {useWeatherConsumer} from 'context/WeatherContext';

type TProps = {
  className?: string;
};
const WeatherInfo = (props: TProps) => {
  const {map} = useAppSelector((state) => state);
  const {sendClickLog} = useLogger();

  const {weatherWidgetProps, enabled} = useWeatherConsumer();

  const onClickButton = useCallback(() => {
    if (!weatherWidgetProps?.error) {
      const lat = map.lastCachedCenter?.lat;
      const lon = map.lastCachedCenter?.lon;
      const userReverseGeoInfo = map.userPositionReverseGeoInfo;
      const centerReverseGeoInfo = map.lastCachedCenterReverseGeoInfo;
      sendClickLog('tap.weather' as TActionId, {
        region_1D: userReverseGeoInfo?.regionName1,
        region_2D: userReverseGeoInfo?.regionName2,
        region_3D: userReverseGeoInfo?.regionName3,
        maplocation_1D: centerReverseGeoInfo?.regionName1,
        maplocation_2D: centerReverseGeoInfo?.regionName2,
        maplocation_3D: centerReverseGeoInfo?.regionName3,
      });
      moveToUrl(`tmap://weather?top=true&lat=${lat}&lon=${lon}`);
    }
  }, [
    map.lastCachedCenter?.lat,
    map.lastCachedCenter?.lon,
    map.lastCachedCenterReverseGeoInfo,
    map.userPositionReverseGeoInfo,
    sendClickLog,
    weatherWidgetProps?.error,
  ]);

  if (!enabled) {
    return null;
  }
  return (
    <div className={classNames(s.wrap, props.className)}>
      <WeatherWidget
        fcstCondition={weatherWidgetProps?.fcstCondition || ''}
        fcstValue={weatherWidgetProps?.fcstValue}
        imageUrl={weatherWidgetProps?.imageUrl || ''}
        hcode={weatherWidgetProps?.hcode || ''}
        onClick={onClickButton}
        error={weatherWidgetProps?.error}
      />
    </div>
  );
};

export default WeatherInfo;
