import {useAppSelector} from 'ducks/hooks';
import {TAdsItem} from 'hooks/useTMapAds';
import {createContext, useContext, useEffect, useState} from 'react';

type TAdBannerContextValue = {
  isAdTop: boolean;
  setIsAdTop: (value: boolean) => void;
  topAdItem: Nullable<TAdsItem>;
  setTopAdItem: (item: TAdsItem) => void;
};

const AdBannerContext = createContext<TAdBannerContextValue>({
  isAdTop: false,
  setIsAdTop: () => {},
  topAdItem: null,
  setTopAdItem: () => {},
});

export const useAdBanner = () => useContext(AdBannerContext);

export const AdBannerProvider = ({children}: {children: React.ReactNode}) => {
  const searchSessionKey = useAppSelector((state) => state.userInfo.searchSessionKey);
  const [isAdTop, setIsAdTop] = useState(false);
  const [topAdItem, setTopAdItem] = useState<Nullable<TAdsItem>>(null);

  // 새로 검색시 초기화
  useEffect(() => {
    setIsAdTop(false);
    setTopAdItem(null);
  }, [searchSessionKey]);

  return (
    <AdBannerContext.Provider
      value={{
        isAdTop,
        setIsAdTop,
        topAdItem,
        setTopAdItem,
      }}
    >
      {children}
    </AdBannerContext.Provider>
  );
};
