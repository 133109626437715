import TMapSender from '@lcc/tmap-inapp';
import React, {ErrorInfo, PropsWithChildren, ReactNode} from 'react';

type Props = PropsWithChildren<{
  fallback: (error: Error) => ReactNode;
  onError?: (args: {error: Error; errorInfo: ErrorInfo}) => void;
}>;

export class ErrorBoundary extends React.Component<Props, {error: Error | null}> {
  constructor(props: Props) {
    super(props);
    this.state = {error: null};
  }

  static getDerivedStateFromError(error: Error) {
    return {error};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    TMapSender.makeToast(JSON.stringify(error));
    this.props.onError?.({error, errorInfo});
  }

  render() {
    if (this.state.error && this.props.fallback) {
      return this.props.fallback(this.state.error);
    }
    return this.props.children;
  }
}
