import {PropsWithChildren} from 'react';
import classNames from 'classnames';
import {EButtonType, TButtonAction} from 'types/Button';
import {callShareInterface} from 'utils/tmapInApp';
import {TShareData} from 'types/App';
import {HistoryBackLink} from 'components/HistoryBackLink';

import {ReactComponent as IconArrow} from 'resource/images/@tmds_element/ico_arrow_left.svg';
import {ReactComponent as IconClose} from 'resource/images/@tmds_basic/ico_exit.svg';
import {ReactComponent as IconShare} from 'resource/images/@tmds_basic/ico_share.svg';

import s from 'styles/components/Header.module.scss';

type TProps = PropsWithChildren<
  {
    leftButton: EButtonType;
    rightButton?: EButtonType;
    title?: string | JSX.Element;
    shareDate?: TShareData;
    gradient?: boolean;

    onClickLeft?: VoidFunction;
    onClickRight?: VoidFunction;
  } & TButtonAction
>;

type TButtonParams = {
  type: EButtonType;
  shareDate?: TShareData;
  onClick?: VoidFunction;
} & TButtonAction;

const getButton = ({type, shareDate, onGoBack, onClose, onShare, onClick}: TButtonParams) => {
  const styles = classNames(s.icon, s[type]);

  switch (type) {
    case EButtonType.BACK:
      return (
        <HistoryBackLink className={styles} onGoBack={onGoBack} onClick={onClick}>
          <IconArrow width={28} height={28} />
        </HistoryBackLink>
      );

    case EButtonType.CLOSE: {
      return (
        <HistoryBackLink className={styles} onGoBack={onClose} onClick={onClick}>
          <IconClose width={24} height={24} />
        </HistoryBackLink>
      );
    }

    case EButtonType.SHARE: {
      return (
        <a
          href="#share"
          className={styles}
          onClick={(e) => {
            e.preventDefault();
            onClick?.();

            shareDate &&
              callShareInterface(shareDate, {
                onSuccess: () => onShare?.(),
                onFail: () => {},
              });
          }}
          draggable={false}
        >
          <IconShare width={28} height={28} />
        </a>
      );
    }
  }
};

const Header = ({
  leftButton,
  rightButton,
  title,
  shareDate,
  gradient = false,

  onClose,
  onShare,
  onGoBack,

  onClickLeft,
  onClickRight,

  children,
}: TProps) => {
  return (
    <div
      className={classNames(s.header_wrap, {
        [s.gradient]: gradient,
      })}
    >
      {leftButton && (
        <div className={s.button} aria-label="왼쪽 버튼">
          {getButton({
            type: leftButton,
            shareDate,
            onClose,
            onShare,
            onGoBack,
            onClick: () => onClickLeft?.(),
          })}
        </div>
      )}

      {children ? (
        <div className={s.children}>{children}</div>
      ) : (
        <h1 className={classNames(s.title, {[s.is_empty]: !title})}>{title}</h1>
      )}

      {rightButton && (
        <div className={s.button} aria-label="오른쪽 버튼">
          {getButton({
            type: rightButton,
            shareDate,
            onClose,
            onShare,
            onGoBack,
            onClick: () => onClickRight?.(),
          })}
        </div>
      )}
    </div>
  );
};

export default Header;
