import {useState, useRef, useEffect, useCallback} from 'react';
import {useGesture} from 'react-use-gesture';

import {
  TListDrawerResult,
  EListMode,
  TModeResult,
  TSizeResult,
  EParallaxMoveMode,
  TPositionResult,
  TProps,
  EDirection,
} from 'types/ListDrawer';
import useDimmedQuery from './useDimmedQuery';
import {devLog} from 'utils/dev';
import TMapSender from '@lcc/tmap-inapp';

const CENTER_HEIGHT = 300;
const TOP_AREA_HEIGHT = 41;
const TOP_AREA_PADDING = 13;
const LIST_HANDLE_HEIGHT = 62;
export const MOVE_DELAY = 300;

const useListDrawer = ({
  windowHeight,
  statusBarHeight,
  isLandscape,
  centerHeight = CENTER_HEIGHT,
  topAreaHeight = TOP_AREA_HEIGHT,
  topAreaPadding = TOP_AREA_PADDING,
  listHandleHeight = LIST_HANDLE_HEIGHT,
  initListMode = EListMode.TOP,
}: TProps): TListDrawerResult => {
  const refInitListMode = useRef(initListMode);
  const {isOpen: isDimmedOpen} = useDimmedQuery();
  const [nowSize, setSize] = useState<TSizeResult>({
    statusBarHeight: 0,
    isLandscape: false,
    windowHeight: windowHeight,

    topPoint: 0,
    centerPoint: 0,
    bottomPoint: 0,

    mapTopPoint: 0,
    mapBottomPoint: 0,
  });

  const [nowMode, setMode] = useState<TModeResult>({
    isTop: refInitListMode.current === EListMode.TOP,
    isBottom: refInitListMode.current === EListMode.BOTTOM,
    isCenter: refInitListMode.current === EListMode.CENTER,
    isStarted: false,

    moveMode: EParallaxMoveMode.WAIT,
    mode: refInitListMode.current,
  });

  const [direction, setDirection] = useState<EDirection>(EDirection.CENTER);
  const [isDragging, setIsDragging] = useState(false);

  const [nowPosition, setPosition] = useState<TPositionResult>({
    map: 0,
    list: 0,
    percent: 0,
    moveDelay: 0,
    nextMoveDelay: 0,
  });

  const gestureState = useRef({
    startPoint: 0,
    startMode: refInitListMode.current,
    inGesture: false,
  });

  const [basePoint, setBasePoint] = useState<number>(0);
  const [safeAreaBottom, setSafeAreaBottom] = useState<number>(0);

  const bind = useGesture(
    {
      onDrag: (state) => {
        try {
          const {
            down,
            movement: [, my],
            swipe,
            dragging,
          } = state;

          if (isDimmedOpen || isLandscape) {
            return;
          }

          setIsDragging(dragging);
          setDirection(state.direction[1]);

          if (down) {
            if (!gestureState.current.inGesture && nowMode.moveMode === EParallaxMoveMode.WAIT) {
              gestureState.current.startPoint = basePoint;
              gestureState.current.inGesture = true;
              gestureState.current.startMode = nowMode.mode;

              setMode((prevState) => ({
                ...prevState,
                moveMode: EParallaxMoveMode.GESTURE,
              }));
            }

            setBasePoint(gestureState.current.startPoint - my);
          } else {
            if (gestureState.current.inGesture) {
              gestureState.current.inGesture = false;
              let result = gestureState.current.startPoint - my;

              if (result < nowSize.bottomPoint) {
                result = nowSize.bottomPoint;
              } else if (result > nowSize.topPoint) {
                result = nowSize.topPoint;
              }

              setBasePoint(result);

              if (swipe[1] !== 0) {
                if (
                  (swipe[1] === 1 && nowMode.mode === EListMode.TOP) ||
                  (swipe[1] === -1 && nowMode.mode === EListMode.BOTTOM)
                ) {
                  moveCenterMode();
                } else if (nowMode.mode === EListMode.CENTER) {
                  if (swipe[1] === 1) {
                    moveBottomMode();
                  } else {
                    moveTopMode();
                  }
                } else {
                  setMode((prevState) => ({
                    ...prevState,
                    moveMode: EParallaxMoveMode.WAIT,
                  }));
                }
              } else {
                if (result > Math.round((nowSize.topPoint + nowSize.centerPoint) / 2)) {
                  moveTopMode();
                } else if (result < Math.round((nowSize.centerPoint + nowSize.bottomPoint) / 2)) {
                  moveBottomMode();
                } else {
                  moveCenterMode();
                }
              }
            }
          }
        } catch (e) {
          TMapSender.makeToast(JSON.stringify(e));
          devLog(e);
        }
      },
    },
    {
      drag: {rubberband: true, axis: 'y'},
    }
  );

  useEffect(() => {
    const handleLoad = () => {
      const safeArea = getComputedStyle(document.documentElement).getPropertyValue('--sab');
      setSafeAreaBottom(parseInt(safeArea, 10) || 0);

      window.removeEventListener('load', handleLoad);
    };
    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  useEffect(() => {
    const nowStatusBarHeight = statusBarHeight || 0;
    const nowLandscape = isLandscape || false;

    const topPoint = windowHeight - topAreaHeight - topAreaPadding - nowStatusBarHeight;
    const bottomPoint = listHandleHeight;
    const centerPoint =
      windowHeight - topAreaHeight - nowStatusBarHeight - centerHeight - safeAreaBottom;

    const mapTopPoint = Math.round((centerPoint - topAreaHeight) / 2) || 0;
    const mapBottomPoint = Math.round((listHandleHeight - topAreaPadding) / 2) || 0;

    const selectBasePoint = {
      [EListMode.TOP]: topPoint,
      [EListMode.CENTER]: centerPoint,
      [EListMode.BOTTOM]: bottomPoint,
    }[refInitListMode.current];

    const toBeBasePoint = selectBasePoint || centerPoint;

    setSize({
      statusBarHeight: nowStatusBarHeight,
      isLandscape: nowLandscape,
      windowHeight: windowHeight,
      topPoint,
      centerPoint,
      bottomPoint,
      mapTopPoint,
      mapBottomPoint,
    });

    setBasePoint(toBeBasePoint);

    setMode((prevState) => ({
      ...prevState,
      isTop: refInitListMode.current === EListMode.TOP,
      isBottom: refInitListMode.current === EListMode.BOTTOM,
      isCenter: refInitListMode.current === EListMode.CENTER,
      moveMode: EParallaxMoveMode.WAIT,
      mode: refInitListMode.current,
    }));
  }, [
    windowHeight,
    statusBarHeight,
    isLandscape,
    centerHeight,
    topAreaHeight,
    topAreaPadding,
    listHandleHeight,
    safeAreaBottom,
  ]);

  useEffect(() => {
    const nowPoint = Math.min(nowSize.topPoint, Math.max(nowSize.bottomPoint, basePoint)) || 0;

    const percentPosition =
      (nowPoint - nowSize.bottomPoint) / (nowSize.topPoint - nowSize.bottomPoint);
    let mapPosition = 0;

    if (nowPoint <= nowSize.centerPoint) {
      const halfPercent =
        (nowPoint - nowSize.bottomPoint) / (nowSize.centerPoint - nowSize.bottomPoint);

      mapPosition =
        (nowSize.mapTopPoint - nowSize.mapBottomPoint) * halfPercent + nowSize.mapBottomPoint;
    } else {
      mapPosition = nowSize.mapTopPoint;
    }

    setPosition((prevState) => ({
      ...prevState,
      moveDelay: prevState.nextMoveDelay || 0,
      nextMoveDelay: 0,
      map: mapPosition || 0,
      list: nowPoint || 0,
      percent: percentPosition || 0,
    }));

    setMode((prevState) => ({
      ...prevState,
      isTop: nowPoint === nowSize.topPoint,
      isBottom: nowPoint === nowSize.bottomPoint,
      isCenter: nowPoint === nowSize.centerPoint,
    }));
  }, [basePoint, nowSize]);

  const moveTopMode = useCallback(() => {
    if (nowMode.isStarted) {
      try {
        setBasePoint((prevBasePoint) => {
          const maxDistance =
            prevBasePoint > nowSize.centerPoint
              ? nowSize.topPoint - nowSize.centerPoint
              : nowSize.topPoint - nowSize.bottomPoint;

          setPosition((prevState) => ({
            ...prevState,
            nextMoveDelay:
              Math.min((nowSize.topPoint - prevBasePoint) / maxDistance, 1) * MOVE_DELAY,
          }));
          setMode((prevState) => ({
            ...prevState,
            mode: EListMode.TOP,
            moveMode: EParallaxMoveMode.MOVE,
          }));

          let timer;
          const timeoutHandler = () => {
            setMode((prevState) => ({
              ...prevState,
              moveMode: EParallaxMoveMode.WAIT,
            }));

            window.clearTimeout(timer);
          };

          timer = window.setTimeout(timeoutHandler, MOVE_DELAY);

          return nowSize.topPoint;
        });
      } catch (e) {
        TMapSender.makeToast(JSON.stringify(e));
        devLog(e);
      }
    }
  }, [nowMode.isStarted, nowSize]);

  const moveCenterMode = useCallback(() => {
    if (nowMode.isStarted) {
      try {
        setBasePoint((prevBasePoint) => {
          const maxDistance =
            prevBasePoint > nowSize.centerPoint
              ? nowSize.topPoint - nowSize.centerPoint
              : nowSize.centerPoint - nowSize.bottomPoint;

          setPosition((prevState) => ({
            ...prevState,
            nextMoveDelay:
              Math.min(
                (prevBasePoint > nowSize.centerPoint
                  ? prevBasePoint - nowSize.centerPoint
                  : nowSize.centerPoint - prevBasePoint) / maxDistance,
                1
              ) * MOVE_DELAY,
          }));
          setMode((prevState) => ({
            ...prevState,
            mode: EListMode.CENTER,
            moveMode: EParallaxMoveMode.MOVE,
          }));

          let timerId = window.setTimeout(() => {
            // eslint-disable-next-line max-nested-callbacks
            setMode((prevState) => ({
              ...prevState,
              moveMode: EParallaxMoveMode.WAIT,
            }));

            window.clearTimeout(timerId);
          }, MOVE_DELAY);

          return nowSize.centerPoint;
        });
      } catch (e) {
        TMapSender.makeToast(JSON.stringify(e));
        devLog(e);
      }
    }
  }, [nowMode.isStarted, nowSize.bottomPoint, nowSize.centerPoint, nowSize.topPoint]);

  const moveBottomMode = useCallback(() => {
    if (nowMode.isStarted) {
      try {
        setBasePoint((nowBasePoint) => {
          const maxDistance =
            nowBasePoint > nowSize.centerPoint
              ? nowSize.topPoint - nowSize.bottomPoint
              : nowSize.centerPoint - nowSize.bottomPoint;

          setPosition((prevState) => ({
            ...prevState,
            nextMoveDelay:
              Math.min((nowBasePoint - nowSize.bottomPoint) / maxDistance, 1) * MOVE_DELAY,
          }));
          setMode((prevState) => ({
            ...prevState,
            mode: EListMode.BOTTOM,
            moveMode: EParallaxMoveMode.MOVE,
          }));

          let timerId = window.setTimeout(() => {
            // eslint-disable-next-line max-nested-callbacks
            setMode((prevState) => ({
              ...prevState,
              moveMode: EParallaxMoveMode.WAIT,
            }));
            window.clearTimeout(timerId);
          }, MOVE_DELAY);

          return nowSize.bottomPoint;
        });
      } catch (e) {
        TMapSender.makeToast(JSON.stringify(e));
        devLog(e);
      }
    }
  }, [nowMode.isStarted, nowSize.bottomPoint, nowSize.centerPoint, nowSize.topPoint]);

  return {
    position: nowPosition,
    size: nowSize,
    mode: nowMode,

    basePoint,

    handlers: bind,
    init: () => setMode((prevState) => ({...prevState, isStarted: true})),

    direction,
    isDragging,

    moveTopMode,
    moveCenterMode,
    moveBottomMode,
  };
};

export default useListDrawer;
