import {useEffect, useState, useCallback, useRef, useMemo} from 'react';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';
import {getAddressMapFromReverseGeo} from 'utils/special';
import {fetchTRankList} from 'ducks/rank/slice';
import useTplaceReverseGeocoding from 'hooks/useTplaceReverseGeocoding';
import {EAddressMode} from 'types/Search';
import {
  NATIONAL_REGION,
  THREE_DEPTH_REGION,
  WHOLE_REGION,
} from 'components/search/SearchRankingDropDown';
import IconMapPinSrc from 'resource/images/tamp_ranking_map_pin@3x.png';
import classNames from 'classnames';
import usePlaceFilter from 'hooks/usePlaceFilter';
import {EPlaceCategoryType} from 'types/App';
import useLogger from 'hooks/useLogger';

import s from 'styles/components/ranking/RankingLocationButton.module.scss';

type TPRops = {
  onClick?: () => void;
};

const RankingLocationButton = ({onClick}: TPRops) => {
  const {currentAddressMap, lastCachedCenter, isLandscape, dragMap} = useAppSelector((state) => ({
    currentAddressMap: state.tRank.currentAddressMap,
    lastCachedCenter: state.map.lastCachedCenter,
    isLandscape: state.layout.appSize.isLandscape,
    dragMap: state.userInteraction.dragMap,
  }));
  const dispatch = useAppDispatch();
  const [location, setLocation] = useState<Nullable<string>>(null);
  const [showRefresh, setShowRefresh] = useState<boolean>(false);
  const refPrevLocation = useRef<string | null>(null);
  const locationText = useMemo(() => location || refPrevLocation.current, [location]);
  const isLongText = useMemo(() => !!locationText && locationText.length >= 9, [locationText]);
  const {sendClickLog} = useLogger();

  const reverseGeo = useTplaceReverseGeocoding({
    lat: lastCachedCenter?.lat,
    lon: lastCachedCenter?.lon,
  });
  const {nowType} = usePlaceFilter();

  const handleClickRefresh = useCallback(() => {
    if (!showRefresh) {
      return;
    }

    onClick?.();
    const {region1Code, region2Code, region3Code, region1Name} = reverseGeo.data || {};
    const isMainCity = THREE_DEPTH_REGION.includes(region1Name || '');

    if (!region1Code) {
      return;
    }

    dispatch(actions.tRank.resetFilter());
    dispatch(actions.tRank.resetSort());
    dispatch(actions.tRank.resetList());
    dispatch(actions.userInteraction.setDragMap(false));
    dispatch(
      fetchTRankList({
        areaCode1: region1Code,
        areaCode2: region2Code,
        areaCode3: isMainCity ? region3Code : undefined,
      })
    );
    sendClickLog('tap.re_search');

    const newAddressMap = getAddressMapFromReverseGeo(reverseGeo.data);
    dispatch(actions.tRank.updateCurrentAddressMap(newAddressMap));
    setShowRefresh(false);
  }, [showRefresh, reverseGeo.data, dispatch]);

  useEffect(() => {
    if (reverseGeo.loading || (!dragMap && lastCachedCenter?.from === 'map')) {
      return;
    }

    const {region1Name, region2Name, region3Name} = reverseGeo.data || {};
    const {areaName: area1Name} = currentAddressMap[EAddressMode.CATE1] || {};
    const {areaName: area2Name} = currentAddressMap[EAddressMode.CATE2] || {};
    const {areaName: area3Name} = currentAddressMap[EAddressMode.CATE3] || {};

    if (area1Name === NATIONAL_REGION.areaName) {
      setShowRefresh(false);
      return;
    }

    if (!area2Name || area2Name === WHOLE_REGION) {
      const isSameLocation = region1Name === area1Name;
      setShowRefresh(!isSameLocation);
      return;
    }

    if (!area3Name || area3Name === WHOLE_REGION) {
      const isSameLocation = region1Name === area1Name && region2Name === area2Name;
      setShowRefresh(!isSameLocation);
      return;
    }

    const isMainCity = THREE_DEPTH_REGION.includes(area1Name || '');
    const isSameLocation = isMainCity
      ? region1Name === area1Name &&
        region2Name === area2Name &&
        (region3Name === area3Name || area3Name === WHOLE_REGION)
      : region1Name === area1Name && (region2Name === area2Name || area2Name === WHOLE_REGION);

    if (isSameLocation) {
      setShowRefresh(false);
    } else {
      setShowRefresh(true);
    }
  }, [reverseGeo]);

  useEffect(() => {
    if (!showRefresh) {
      return;
    }

    const {region1Name, region2Name, region3Name} = reverseGeo.data || {};
    const {areaName: area1Name} = currentAddressMap[EAddressMode.CATE1] || {};
    const {areaName: area2Name} = currentAddressMap[EAddressMode.CATE2] || {};

    if (!region1Name) {
      setLocation(null);
      return;
    }

    if ((!area2Name || area2Name === WHOLE_REGION) && area1Name === region1Name) {
      setLocation(region1Name);
      refPrevLocation.current = region1Name;
      return;
    }

    const displayedLocation =
      region1Name && THREE_DEPTH_REGION.includes(region1Name)
        ? `${region2Name} ${region3Name}`
        : `${region1Name} ${region2Name}`;

    setLocation(displayedLocation);
    refPrevLocation.current = displayedLocation;
  }, [showRefresh, reverseGeo.data, dispatch]);

  // map reverseGeo store에 저장. 데이터 형식이 달라서 ts-ignore 사용
  useEffect(() => {
    dispatch(
      // @ts-ignore
      actions.map.setLastCachedCenterGeoInfo({
        regionName1: reverseGeo.data?.region1Name || '',
        regionName2: reverseGeo.data?.region2Name || '',
        regionName3: reverseGeo.data?.region3Name || '',
      })
    );
  }, [dispatch, reverseGeo.data]);

  useEffect(() => {
    const region1Area = currentAddressMap[EAddressMode.CATE1];
    const region1AreaName = region1Area?.areaName;
    const region3AreaName = currentAddressMap[EAddressMode.CATE3]?.areaName;
    const lastTwoDepthValue =
      THREE_DEPTH_REGION.includes(region1AreaName || '') && region3AreaName !== WHOLE_REGION
        ? Object.values(currentAddressMap).slice(-2)
        : Object.values(currentAddressMap).slice(0, 2);

    if (!lastTwoDepthValue.length) {
      return;
    }

    const secondLastName = lastTwoDepthValue[0]?.areaName;
    const lastName =
      lastTwoDepthValue[1]?.areaName === WHOLE_REGION ? '' : lastTwoDepthValue[1]?.areaName || '';

    const displayedLocation = `${secondLastName} ${lastName}`;

    setLocation(displayedLocation);
    refPrevLocation.current = displayedLocation;
    setShowRefresh(false);
  }, [currentAddressMap]);

  useEffect(() => {
    if (location || !reverseGeo.data) {
      return;
    }

    const {region1Name} = reverseGeo.data;

    if (!region1Name) {
      refPrevLocation.current = null;
    }
  }, [reverseGeo.data, location]);

  if ((!location && !refPrevLocation.current) || nowType !== EPlaceCategoryType.RANKING) {
    return null;
  }

  return (
    <div
      className={classNames(s.wrap, {
        [s.landscape]: isLandscape,
      })}
    >
      <div
        className={classNames(s.container, {
          [s.refresh]: showRefresh,
        })}
        onClick={handleClickRefresh}
      >
        <div className={s.location}>
          {showRefresh ? (
            <div
              className={classNames(s.text, {
                [s.long_text]: isLongText,
              })}
            >
              <div className={s.top_text}>
                <img className={s.icon} src={IconMapPinSrc} alt="ranking" />
                <span className={s.new}>{locationText}</span>
                에서
              </div>
              <span className={s.research_text}>재탐색</span>
            </div>
          ) : (
            <>
              <img className={s.icon} src={IconMapPinSrc} alt="ranking" />
              <div className={s.text}>{location}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RankingLocationButton;
