import {Suspense, useEffect} from 'react';
import {useAppDispatch} from 'ducks/hooks';
import {BrowserRouter as Router} from 'react-router-dom';

import actions from 'ducks/actions';
import useWindowSize from 'hooks/useWindowSize';
import renderNestedRouter from 'utils/renderNestedRouter';
import useRoutePrefetch from 'hooks/useRoutePrefetch';

import routes from './routes';

import '@egjs/react-flicking/dist/flicking.css';

const App = () => {
  const dispatch = useAppDispatch();
  const nowWindowSize = useWindowSize();

  useRoutePrefetch();

  useEffect(() => {
    dispatch(
      actions.layout.windowResize({width: nowWindowSize.width, height: nowWindowSize.height})
    );

    if (nowWindowSize.isLandscape !== null) {
      dispatch(actions.layout.setLandscape(nowWindowSize.isLandscape));
    }
  }, [dispatch, nowWindowSize]);

  return (
    <Router>
      <Suspense fallback={<div />}>{renderNestedRouter(routes)}</Suspense>
    </Router>
  );
};

export default App;
