import {PropsWithChildren, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useLocation} from 'react-router-dom';

import {TRouter} from 'types/Router';
import {LAYER_ID} from 'types/App';
import {EOrientation} from 'types/Device';

import {SearchServicePages} from 'constant/RoutePath';
import {PAGE_TITLE_PREFIX} from 'constant/Text';

import {useAppSelector} from 'ducks/hooks';
import useUserData from 'hooks/useUserData';
import useMapContext from 'hooks/useMapContext';
import useInitApiHeader from 'hooks/useInitApiHeader';

import SearchResultPage from 'pages/SearchResultPage';

import renderNestedRouter from 'utils/renderNestedRouter';

import {DimmedLayer} from 'components/DimmedLayer';
import BuildInfo from 'components/BuildInfo';
import {useSearchPageVisible} from 'hooks/useSearchPageVisible';
import {SearchMainPage} from 'pages/SearchMainPage';
import useHybridBridge from 'hooks/useHybridBridge';
import {useRemoteConfig} from 'hooks/useRemoteConfig';
import {SEARCH_CONFIG} from 'constant/Firebase';
import {ErrorBoundary} from 'components/ErrorBoundary';

type TProps = PropsWithChildren<{
  className?: string;
  routes?: TRouter[];
}>;

const SearchLayout = ({className, children, routes = []}: TProps) => {
  const {pathname} = useLocation();
  const {isLandscape} = useAppSelector((state) => ({
    isLandscape: state.layout.appSize.isLandscape,
  }));
  const {showMain, showResult} = useSearchPageVisible();

  useInitApiHeader();
  useRemoteConfig(SEARCH_CONFIG);
  useUserData();
  useMapContext();
  useHybridBridge();

  useEffect(() => {
    document.body.setAttribute(
      'data-orientation',
      isLandscape ? EOrientation.LANDSCAPE : EOrientation.PORTRAIT
    );
  }, [isLandscape]);

  useEffect(() => {
    document.body.setAttribute('data-safe-area', 'true');
  }, []);

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_PREFIX} 검색 </title>
      </Helmet>

      <div className={className}>
        {children}
        <SearchMainPage hide={!showMain} />
        <ErrorBoundary
          fallback={(error) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              [오류페이지]
              <div>{JSON.stringify(error)}</div>
              <button
                onClick={() => {
                  window.location.reload();
                }}
              >
                새로고침 버튼
              </button>
            </div>
          )}
        >
          <SearchResultPage hide={!showResult} />
        </ErrorBoundary>
        {/* 서비스 페이지가 아닌 url 접근시 redirect */}
        {!SearchServicePages.includes(pathname) && renderNestedRouter(routes)}
        <BuildInfo isSearch={true} />
        <div id={LAYER_ID} />
        <DimmedLayer />
      </div>
    </>
  );
};

export default SearchLayout;
