import {isDtgEnv, isLocalOrDev} from 'constant/Env';
import {useState} from 'react';
import {getValueToString} from 'utils/string';

type TProps = {
  left?: number;
  bottom?: number;
  btnTitle?: string;
  infos?: {value: object; color?: string}[];
  color?: string;
};

const DevInfo = ({left = 0, bottom = 0, btnTitle = 'Show Dev Info', infos, color}: TProps) => {
  const [viewDevInfo, toggleDevInfo] = useState<boolean>(false);

  return isLocalOrDev || isDtgEnv ? (
    <div
      role="presentation"
      aria-hidden="true"
      style={{
        position: 'fixed',
        left,
        bottom,
        color,
        fontSize: 10,
        zIndex: 99999,
        background: 'rgba(255, 255, 255, 0.6)',
      }}
      onClick={() => toggleDevInfo(!viewDevInfo)}
    >
      {viewDevInfo &&
        infos?.map((nowInfo, i) => (
          <div key={`nowInfo-${i}`} style={{color: nowInfo.color}}>
            {Object.keys(nowInfo.value).map((n) => (
              <div key={n} style={{display: 'flex'}}>
                <div style={{width: 140}}>{n}</div>
                <div style={{flex: 1}}>{getValueToString(nowInfo.value[n])}</div>
              </div>
            ))}
          </div>
        ))}

      {!viewDevInfo && btnTitle}
    </div>
  ) : null;
};

export default DevInfo;
