import {useEffect} from 'react';
import {PlaceRoutes, SearchRoutes} from 'constant/RoutePath';
import routes from '../routes';

const useRoutePrefetch = () => {
  useEffect(() => {
    const prefetchRoutes = () => {
      const routesToPrefetch = [SearchRoutes, PlaceRoutes];

      routesToPrefetch.forEach((path) => {
        const route = routes.find((r) => r.path === path);
        if (route?.component) {
          const Component = route.component as {preload?: () => void};
          typeof Component.preload === 'function' && Component.preload();
        }
      });
    };

    prefetchRoutes();
  }, []);
};

export default useRoutePrefetch;
