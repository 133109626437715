import {FIRE_BASE_WEBAPP_CONFIG} from 'constant/Firebase';

const getFirebaseSdk = () => {
  return Promise.allSettled([import('firebase/app'), import('firebase/remote-config')]).then(
    (sdks) => {
      const [appResp, remoteConfigResp] = sdks;

      return {
        FirebaseApp: appResp.status === 'fulfilled' ? appResp.value : undefined,
        FirebaseRemoteConfig:
          remoteConfigResp.status === 'fulfilled' ? remoteConfigResp.value : undefined,
      };
    }
  );
};

export const fetchRemoteConfigFromFirebase = async (configScope) => {
  let FirebaseApp, FirebaseRemoteConfig;

  try {
    const LoadedSdk = await getFirebaseSdk();

    FirebaseApp = LoadedSdk.FirebaseApp;
    FirebaseRemoteConfig = LoadedSdk.FirebaseRemoteConfig;
  } catch (e) {
    throw e;
  }

  FirebaseApp.initializeApp(FIRE_BASE_WEBAPP_CONFIG);

  const remoteConfig = FirebaseRemoteConfig.getRemoteConfig();

  remoteConfig.settings.minimumFetchIntervalMillis = 0;

  return FirebaseRemoteConfig.fetchAndActivate(remoteConfig).then(() => {
    const config = configScope.reduce((prev, cur) => {
      let options = {};
      try {
        options = JSON.parse(FirebaseRemoteConfig.getString(remoteConfig, cur));
      } catch (e) {}

      return {
        ...prev,
        ...options,
      };
    }, {});

    return config;
  });
};
