import {lazy} from 'react';
import {Redirect} from 'react-router-dom';

import {TRouter} from 'types/Router';
import {
  DevRoutes,
  PageRoutes,
  Paths,
  PlaceRoutes,
  ProtoRoutes,
  SearchRoutes,
} from 'constant/RoutePath';
import {isProdEnv} from 'constant/Env';
import styles from 'styles/pages/Routes.module.scss';
import {isNewApp} from 'utils/tmapUtils';
import {EPlaceCategoryType} from 'types/App';

import CommonLayout from 'components/CommonLayout';
import SearchLayout from 'components/search/SearchLayout';
import LegacySearchLayout from 'components/legacySearch/LegacySearchLayout';

// import PlaceMainPage from 'pages/PlaceMainPage';
import PlaceHomePage from 'pages/PlaceHomePage'; // 어디갈까 탭으로 진입시 팝업 미노출 이슈로 lazy loading 제거
const PlaceCategoryPage = lazy(() => import('pages/PlaceCategoryPage'));
const RankingPage = lazy(() => import('pages/RankingPage'));
const FestivalPage = lazy(() => import('pages/FestivalPage'));

const ProtoPage = lazy(() => import('pages/ProtoPage'));
const ProtoListDrawerPage = lazy(() => import('pages/ProtoListDrawerPage'));
const ProtoImagePage = lazy(() => import('pages/ProtoImagePage'));
const ProtoAreaWordsPage = lazy(() => import('pages/ProtoAreaWordsPage'));
const ProtoCustomSelectPage = lazy(() => import('pages/ProtoCustomSelectPage'));
const ProtoPubImagePage = lazy(() => import('pages/ProtoPubImagePage'));
const ProtoBannerPage = lazy(() => import('pages/ProtoBannerPage'));
const ProtoKeywordPage = lazy(() => import('pages/ProtoKeywordPage'));
const ProtoCurationPage = lazy(() => import('pages/ProtoCurationPage'));

const DevPage = lazy(() => import('pages/DevPage'));
const DevRedDotPage = lazy(() => import('pages/DevRedDotPage'));
const DevDeepLinkPage = lazy(() => import('pages/DevDeepLinkPage'));
const DevHybridBridgePage = lazy(() => import('pages/DevHybridBridgePage'));
const DevVSMTNowTestPage = lazy(() => import('pages/DevVSMTNowTestPage'));
const DevAppInterfacePage = lazy(() => import('pages/DevAppInterfacePage'));

const routes: TRouter[] = [
  {
    path: SearchRoutes,
    component: (props) =>
      isNewApp() ? (
        <SearchLayout {...{...props, className: styles.search_wrap}} />
      ) : (
        <LegacySearchLayout {...{...props, className: styles.search_wrap}} />
      ),
    routes: [
      {
        component: () => <Redirect to={Paths.SearchMain} />,
      },
    ],
  },
  {
    path: PlaceRoutes,
    component: (props) => <CommonLayout {...{...props, className: styles.place_wrap}} />,
    routes: [
      {
        path: Paths.PlaceMain,
        // component: PlaceMainPage,
        component: PlaceHomePage,
      },
      {
        path: Paths.PlaceCategory,
        component: ({location}) => {
          const searchParams = new URLSearchParams(location.search);
          const type = searchParams.get('type');

          return type === EPlaceCategoryType.RANKING ? <RankingPage /> : <PlaceCategoryPage />;
        },
      },
      {
        component: () => <Redirect to={Paths.PlaceMain} />,
      },
    ],
  },

  {
    path: PageRoutes,
    component: (props) => <CommonLayout {...{...props, className: styles.place_wrap}} />,
    routes: [
      {
        path: Paths.PageFestival,
        component: FestivalPage,
      },
      {
        // 별도 fallback 처리 없음
        component: () => null,
      },
    ],
  },

  {
    path: ProtoRoutes,
    component: (props) =>
      isProdEnv ? (
        <Redirect to={Paths.PlaceMain} />
      ) : (
        <CommonLayout {...{...props, className: styles.place_wrap}} />
      ),
    routes: [
      {
        path: Paths.Proto,
        component: ProtoPage,
      },
      {
        title: '검색 > 배너',
        path: Paths.ProtoBanner,
        component: ProtoBannerPage,
      },
      {
        title: '검색 > 키워드 광고',
        path: Paths.ProtoKeyword,
        component: ProtoKeywordPage,
      },
      {
        title: '티지금 > 메인페이지',
        path: Paths.ProtoListDrawer,
        component: ProtoListDrawerPage,
      },
      {
        title: '티지금 > 이미지',
        path: Paths.ProtoImage,
        component: ProtoImagePage,
      },
      {
        title: '티지금 > 지역검색어',
        path: Paths.ProtoAreaWords,
        component: ProtoAreaWordsPage,
      },
      {
        title: '티지금 > 커스텀셀렉트박스',
        path: Paths.ProtoCustomSelect,
        component: ProtoCustomSelectPage,
      },
      {
        title: '티지금 > Static 이미지',
        path: Paths.ProtoPubImage,
        component: ProtoPubImagePage,
      },

      {
        title: '티플레이스 홈 > 큐레이션',
        path: Paths.ProtoCuration,
        component: ProtoCurationPage,
      },

      {
        component: () => <Redirect to={Paths.Proto} />,
      },
    ],
  },

  {
    path: DevRoutes,
    component: (props) =>
      isProdEnv ? (
        <Redirect to={Paths.PlaceMain} />
      ) : (
        <CommonLayout {...{...props, className: styles.place_wrap}} />
      ),
    routes: [
      {
        path: Paths.Dev,
        component: DevPage,
      },
      {
        title: '검색&티지금 > 딥링크',
        path: Paths.DevDeepLink,
        component: DevDeepLinkPage,
      },
      {
        title: '티지금 > VSM 지도',
        path: Paths.DevVSMTNowTest,
        component: DevVSMTNowTestPage,
      },
      {
        title: '티지금 > 레드닷',
        path: Paths.DevRedDot,
        component: DevRedDotPage,
      },
      {
        title: '검색 > 하이브리드',
        path: Paths.DevHybridBridge,
        component: DevHybridBridgePage,
      },
      {
        title: '검색 > 앱인터페이스',
        path: Paths.DevAppInterface,
        component: DevAppInterfacePage,
      },
      {
        component: () => <Redirect to={Paths.Dev} />,
      },
    ],
  },

  {
    component: () => <Redirect to={Paths.SearchMain} />,
  },
];

export default routes;
