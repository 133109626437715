/**
 * DrawerContainer, DrawerContainer-landscape, callout 등에서 사용해서 context로 개발
 */
import {dfsConvertXY, WeatherWidgetProps} from '@tmap-web-lib/tmap-weather';
import {useAppSelector} from 'ducks/hooks';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {frontmanFetcher} from 'utils/fetcher';
import {isWeatherVersion} from 'utils/tmapUtils';

type TProps = PropsWithChildren<{}>;
type TWidgetProps = Pick<
  WeatherWidgetProps,
  'fcstValue' | 'fcstCondition' | 'hcode' | 'error' | 'imageUrl'
>;

type TContextValue = {
  enabled: boolean;
  weatherWidgetProps?: TWidgetProps;
};

const WeatherContext = createContext<TContextValue>({
  enabled: false,
  weatherWidgetProps: undefined,
});

export const useWeatherConsumer = () => useContext(WeatherContext);

export const WeatherProvider = ({children}: TProps) => {
  const {map, userInfo} = useAppSelector((state) => state);
  const [weatherData, setWeatherData] = useState(null);

  const enabled = useMemo(() => isWeatherVersion(), []);

  const convertedXY = useMemo(() => {
    const lat = map.lastCachedCenter?.lat;
    const lon = map.lastCachedCenter?.lon;
    if (lat && lon) {
      return dfsConvertXY('toXY', parseFloat(String(lat)), parseFloat(String(lon)));
    }
  }, [map.lastCachedCenter?.lat, map.lastCachedCenter?.lon]);

  const fetchWeatherData = useCallback(async () => {
    if (!enabled) {
      return;
    }

    if (convertedXY) {
      try {
        const {x, y} = convertedXY;
        const response = await frontmanFetcher.get(
          `/friday/api/v1/weather/shortterm/grid?x=${y}&y=${x}`
        );
        if (!response) {
          throw new Error('날씨 데이터를 가져오는 데 실패했습니다.');
        }
        setWeatherData(response.data.data);
      } catch (error: any) {
        setWeatherData(null);
      }
    } else {
      setWeatherData(null);
    }
  }, [convertedXY, enabled]);

  const weatherWidgetProps = useMemo<TWidgetProps>(() => {
    // @ts-ignore
    const {weather} = weatherData || {};
    const [todayWeather] = weather || [];
    const {forecast} = todayWeather || {};
    const [currentWeather] = forecast || [];
    const {fcstImageUrl, fcstCondition, fcstValue} = currentWeather || {};
    const {staticImage} = fcstImageUrl || {};

    const error = !weatherData;

    return {
      fcstCondition,
      fcstValue,
      imageUrl: staticImage,
      hcode: '00000', // 차후 구현
      error: error,
    };
  }, [weatherData]);

  const timer = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    // 랭킹페이지에서 accessKey 받아오기전에 렌더되는 현상으로 조건 추가. 차후 개선필요.
    if (userInfo.accessKey) {
      timer.current = setTimeout(() => fetchWeatherData(), 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertedXY?.x, convertedXY?.y, userInfo.accessKey]);

  return (
    <WeatherContext.Provider value={{weatherWidgetProps, enabled}}>
      {children}
    </WeatherContext.Provider>
  );
};
