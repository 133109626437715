import {EPlaceTag, ECategoryGroup} from 'types/App';

import cssValues from 'styles/modules/ExportedVariables.module.scss';

const BLUE_BG_STYE = {
  color: cssValues.tmobiBlue600,
  backgroundColor: cssValues.tmobiBlue100,
};

export const TAG_PROPERTIES = {
  [EPlaceTag.POPULAR]: {
    label: '티맵인기',
    style: BLUE_BG_STYE,
  },
  [EPlaceTag.TV_RESTAURANT]: {
    label: 'TV맛집',
    style: {
      color: cssValues.tmobiGreen600,
      backgroundColor: cssValues.tmobiTeal100,
    },
  },
  [EPlaceTag.TMAP_PAYMENT]: {
    label: '티맵결제',
    style: BLUE_BG_STYE,
  },
  [EPlaceTag.ACCOMMODATION_RESERVATION]: {label: '예약가능'},
  [EPlaceTag.PARKING]: {label: '주차'},
  [EPlaceTag.CHEAPEST]: {
    label: '최저가',
    style: {
      color: cssValues.tmobiGreen600,
      backgroundColor: cssValues.tmobiTeal100,
    },
  },
  [EPlaceTag.TMAP_RESERVATION]: {
    label: '예약',
    style: {
      color: cssValues.tmobiPurple600,
      backgroundColor: cssValues.tmobiPurple100,
    },
  },
  [EPlaceTag.TMAP_WAITING]: {
    label: '웨이팅',
    style: {
      color: cssValues.tmobiPurple600,
      backgroundColor: cssValues.tmobiPurple100,
    },
  },
  [EPlaceTag.NEAREST]: {
    label: '가까운',
    style: {
      color: cssValues.tmobiGreen600,
      backgroundColor: cssValues.tmobiTeal100,
    },
  },
  [EPlaceTag.AUTO_PAYMENT]: {
    label: '자동결제',
    style: BLUE_BG_STYE,
  },
  [EPlaceTag.TRUCK_GAS_STATION]: {label: '화물차우대'},
  [EPlaceTag.TRUCK_REST_AREA]: {label: '화물차쉼터'},
  [EPlaceTag.EV_DC_COMBO]: {label: 'DC콤보'},
  [EPlaceTag.EV_DC_CHADEMO]: {label: 'DC차데모'},
  [EPlaceTag.EV_AC3]: {label: 'AC3상'},
  [EPlaceTag.EV_AC_SLOW]: {label: '완속'},
  [EPlaceTag.EV_CHARGER_OPEN]: {label: '비공용'},
  [EPlaceTag.SA_TF_RECOMMEND]: {
    label: '추천',
    style: {
      color: cssValues.gray600,
      fontFamily: cssValues.font500,
      borderColor: cssValues.gray500,
      backgroundColor: cssValues.white,
    },
  },
  [EPlaceTag.VALET]: {label: '발렛'},
  [EPlaceTag.AD_POI]: {
    label: '광고',
    style: {
      color: cssValues.adText,
      fontFamily: cssValues.font500,
      borderColor: cssValues.adBorder,
    },
  },
  [EPlaceTag.CAR_TUNING]: {label: '경정비'},
  [EPlaceTag.CAR_WASH]: {label: '세차장'},
  [EPlaceTag.CONVENIENCE_STORE]: {label: '편의점'},
  [EPlaceTag.SELF_STATION]: {label: '셀프'},
  [EPlaceTag.ALLDAY_HOURS]: {label: '24시'},
  [EPlaceTag.TMAP_ONLY]: (start, end) => ({
    label: start !== end ? `티맵단독 ${start}~${end}` : `티맵단독 ${start} 한정`,
    style: {
      color: cssValues.tmobiBlue600,
      backgroundColor: cssValues.tmobiBlue100,
    },
  }),
  [EPlaceTag.LOWEST]: {
    label: '최저가보상',
    style: {
      color: cssValues.tmobiPink600,
      backgroundColor: cssValues.tmobiPink100,
    },
  },
  [EPlaceTag.ECO]: {
    label: '친환경',
    style: {
      color: cssValues.tmobiGreen600,
      backgroundColor: cssValues.tmobiTeal100,
    },
  },
  [EPlaceTag.AD_NOTICE]: {
    label: '',
    style: {
      color: cssValues.gray600,
      backgroundColor: cssValues.white,
      borderColor: cssValues.gray100,
    },
  },
};

export const DEFAULT_TAG_LIST = [
  EPlaceTag.AD_POI,
  EPlaceTag.POPULAR,
  EPlaceTag.TV_RESTAURANT,
  EPlaceTag.TMAP_RESERVATION,
  EPlaceTag.TMAP_WAITING,
  EPlaceTag.ECO,
  EPlaceTag.PARKING,
  EPlaceTag.VALET,
];

export const CATEGORY_TAG_MAP = {
  [ECategoryGroup.GAS_CHARGING_STATION]: [
    EPlaceTag.AD_POI,
    EPlaceTag.NEAREST,
    EPlaceTag.CHEAPEST,
    EPlaceTag.PARKING,
    EPlaceTag.VALET,
    EPlaceTag.TRUCK_GAS_STATION,
    EPlaceTag.ALLDAY_HOURS,
    EPlaceTag.SELF_STATION,
    EPlaceTag.CAR_WASH,
    EPlaceTag.CAR_TUNING,
    EPlaceTag.CONVENIENCE_STORE,
  ],
  [ECategoryGroup.PARKING]: [
    EPlaceTag.AD_POI,
    EPlaceTag.TMAP_PAYMENT,
    EPlaceTag.AUTO_PAYMENT,
    EPlaceTag.VALET,
  ],

  [ECategoryGroup.EV_CHARGING_STATION]: [
    EPlaceTag.AD_POI,
    EPlaceTag.NEAREST,
    EPlaceTag.TMAP_PAYMENT,
    EPlaceTag.PARKING,
    EPlaceTag.VALET,
    EPlaceTag.EV_CPO,
    EPlaceTag.EV_CHARGER_OPEN,

    EPlaceTag.EV_DC_CHADEMO,
    EPlaceTag.EV_AC3,
    EPlaceTag.EV_DC_COMBO,
    EPlaceTag.EV_AC_SLOW,
    EPlaceTag.EV_SUPER_CHARGER,
  ],
  [ECategoryGroup.ACCOMMODATION]: [
    EPlaceTag.AD_POI,
    EPlaceTag.PARKING,
    EPlaceTag.VALET,
    EPlaceTag.ACCOMMODATION_RESERVATION,
  ],
  [ECategoryGroup.REST_AREA]: [
    EPlaceTag.AD_POI,
    EPlaceTag.PARKING,
    EPlaceTag.VALET,
    EPlaceTag.TRUCK_REST_AREA,
  ],
};
