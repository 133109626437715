export enum EProjectEnv {
  PROD = 'PROD',
  RTG = 'RTG',
  STAGE = 'STAGE',
  DTG = 'DTG',
  DEV = 'DEV',
  LOCAL = 'LOCAL',
}

export const APP_API_HOST = process.env.REACT_APP_API_HOST;
export const APP_SERVER_NAME = process.env.REACT_APP_SERVER_NAME as EProjectEnv;

export const isProdEnv = APP_SERVER_NAME === EProjectEnv.PROD;
export const isLocalEnv = APP_SERVER_NAME === EProjectEnv.LOCAL;
export const isDtgEnv = APP_SERVER_NAME === EProjectEnv.DTG;
export const isStageEnv = APP_SERVER_NAME === EProjectEnv.STAGE;
export const isRtgEnv = APP_SERVER_NAME === EProjectEnv.RTG;
export const isProdOrStageEnv = [EProjectEnv.PROD, EProjectEnv.STAGE].includes(APP_SERVER_NAME);
export const isLocalOrDev = [EProjectEnv.DEV, EProjectEnv.LOCAL].includes(APP_SERVER_NAME);

export const WEB_ACCESS_KEY = 'web-accesskey';
export const WEB_USER_KEY = 'web-userkey';
export const WEB_AD_ID = 'web-test-ad-id';
export const WEB_DEVICE_ID = 'web-test-device-id';
export const WEB_SESSION_ID = 'web-test-session-id';

export const WEB_DEFAULT_CARRIER = 'SKTelecom';

const env = {
  isProdEnv,
  isLocalEnv,
  isLocalOrDev,
};

export default env;
