import {ETPlaceCurationType, TPlaceCurationContent} from 'ducks/remote/type';
import TPlaceCurationType1 from './TPlaceCurationType1';
import TPlaceCurationType2 from './TPlaceCurationType2';
import TPlaceCurationType3 from './TPlaceCurationType3';

type TProps = {
  item?: TPlaceCurationContent;
  contentIndex: number;
};

const TPlaceCuration = ({item, contentIndex}: TProps) => {
  if (!item) {
    return null;
  }

  if (item.type === ETPlaceCurationType.TYPE_1) {
    return <TPlaceCurationType1 item={item} contentIndex={contentIndex} />;
  }

  if (item.type === ETPlaceCurationType.TYPE_2) {
    return <TPlaceCurationType2 item={item} contentIndex={contentIndex} />;
  }

  if (item.type === ETPlaceCurationType.TYPE_3) {
    return <TPlaceCurationType3 item={item} contentIndex={contentIndex} />;
  }

  if (item.type === ETPlaceCurationType.TYPE_4) {
    return (
      <iframe
        title="큐레이션 비디오"
        id="player"
        width="100%"
        // TODO: height를 300px로 고정해놓은 것은 임시로 넣은 것이므로 추후 수정 필요
        height="300px"
        frameBorder="0"
        src={item.videoUrl}
      />
    );
  }

  return null;
};

export default TPlaceCuration;
