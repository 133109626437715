import {
  TCategoryLinkProperty,
  TCategoryPageProperty,
  TTooltipListProperty,
} from 'ducks/category/types';
import {EEVStationType} from 'types/App';

export type TRemoteExposeTime = {
  exposeTime: {
    start?: string;
    end?: string;
  };
};

export type TThemeMarkerConfig = TRemoteExposeTime & {
  code: string;
  description: string;
  icon: [string, string];
  marker: [string, string];
};

export enum ELandingType {
  // 인앱에서 브라우저 띄우기, openServiceByUrl
  IN_APP = 'inapp',
  // 외부브라우저 띄우기, openBrowser
  EXTERNAL = 'external',
  // ex) tmap://, openBrowser
  APP_SCHEME = 'appscheme',
}

export type TTNowPopupItem = TRemoteExposeTime & {
  type: ELandingType;
  imagePath: string;
  landingUrl: string;
};

export type TEvFilterListItem = {
  id: EEVStationType;
  label: string;
};

export type TRecommendKeywordItem = {
  type: 'search' | 'ad';
  keyword: string;
};

export enum ETPlaceCurationType {
  TYPE_1 = 'type_1',
  TYPE_2 = 'type_2',
  TYPE_3 = 'type_3',
  TYPE_4 = 'type_4',
}

export type TTPlaceCurationItem = {
  poiName: string;
  category: string;
  landingUrl: string;
  landingType: ELandingType;
  thumbnailUrl: string;
};

export type TTPlaceCurationType1 = {
  id: string;
  type: ETPlaceCurationType.TYPE_1;
  title: string;
  list: TTPlaceCurationItem[];
};

export type TTPlaceCurationType2 = {
  id: string;
  type: ETPlaceCurationType.TYPE_2;
  title: string;
  desc: string;
  thumbnailUrl: string;
  list: Array<
    TTPlaceCurationItem & {
      address: string;
    }
  >;
};

export type TTPlaceCurationType3 = {
  id: string;
  type: ETPlaceCurationType.TYPE_3;
  title: string;
  list: Array<{
    thumbnailUrl: string;
    landingUrl: string;
    landingType: ELandingType;
  }>;
};

export type TTPlaceCurationType4 = {
  id: string;
  type: ETPlaceCurationType.TYPE_4;
  videoUrl: string;
  title?: string;
};

export type TPlaceCurationContent =
  | TTPlaceCurationType1
  | TTPlaceCurationType2
  | TTPlaceCurationType3
  | TTPlaceCurationType4;

export type TPlaceContentType =
  | 'CURATION'
  | 'RANKING'
  | 'AD_BANNER_01'
  | 'AD_BANNER_02'
  | 'RANKING_BANNER';

export type TPlaceContentTypeDiscovery = 'TRIP' | 'SIMILAR' | 'WEEKEND' | 'DELICIOUS';

export type TPlaceContentItem = {
  type: TPlaceContentType;
  curationId?: string;
  order?: number;
  exposeRequired?: boolean;
};

export type TPlaceContentItemDiscovery = {
  type: TPlaceContentType | TPlaceContentTypeDiscovery;
  curationId?: string;
};

export type TPlaceEventItem = TRemoteExposeTime & {
  id: string;
};

export type TPlaceSettings = {
  eventList: TPlaceEventItem[];
  aroundContentList: TPlaceContentItem[];
  discoveryContentList: TPlaceContentItemDiscovery[];
  discoveryAdBannerList: TPlaceContentItem[];
  curationContentList: TPlaceCurationContent[];
  discoveryContentOrder?: string[]; // deprecated
};

export type TTnowPlaceholder = {
  text: string;
  screen: string;
  locationInfoIncluded: boolean;
};

export type TSaveMapLevelClustering = {
  minFavCount: number;
  cluster: number[];
};

export type TRemoteConfig = {
  lastUpdateTime?: number;
  rawRemoteData?: Record<string, any>;

  tnowCategoryLink: TCategoryLinkProperty[];
  searchMainCategoryLink: TCategoryLinkProperty[];
  searchResultCategoryLink: TCategoryLinkProperty[];

  customCategoryPage: TCategoryPageProperty[];
  themeMarker: TThemeMarkerConfig[];

  tnowPopupVersion?: number;
  tnowPopupList: TTNowPopupItem[];

  evFilterList: TEvFilterListItem[];

  searchRecommendKeyword: TRecommendKeywordItem[];
  tplaceSettings: TPlaceSettings | undefined;

  tooltipList: TTooltipListProperty[];
  tnowPlaceholder?: TTnowPlaceholder;

  saveMapLevelClustering: TSaveMapLevelClustering;
  ui_ab_type?: 'A' | 'B' | 'C' | '';
};
