import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import TMapSender, {TServiceName} from '@lcc/tmap-inapp';
import ua from 'utils/uaParser';
import {getFirstParam} from 'utils/search';
import {useAppSelector} from 'ducks/hooks';
import {ETMapBannerCode} from 'constant/Ads';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import {useOnce} from 'hooks/useOnce';
import {
  EAdsErrorMessage,
  TAdsItem,
  TRequestSetting,
  TRequestVariableParams,
  useTMapAds,
} from 'hooks/useTMapAds';
import {APP_SERVER_NAME} from 'constant/Env';
import {getApiTime} from 'utils/date';
import {parseDeepLink} from 'utils/url';
import {shuffle} from 'utils/lodash';
import {TLonLat} from 'types/Map';
import {v4 as uuidv4} from 'uuid';
import RankingBanner from 'components/ranking/RankingBanner';
import {useAdBanner} from 'context/AdBannerContext';

import s from 'styles/modules/TMapInternalBanner.module.scss';

type TProps = {
  inventoryCode: ETMapBannerCode;
  bannerLonLat?: TLonLat;
  onStart?: (reqParams?: TRequestSetting) => void;
  onShow?: (data: TAdsItem) => void;
  onError?: (error?: Error) => void;
  onClickAd?: (url: string, data?: TAdsItem) => void;
  extraLogCallTime?: number;
  isInView?: boolean;
};

const TEMP_ADID = uuidv4();

const TMapInternalBanner = ({
  inventoryCode,
  bannerLonLat,
  onStart,
  onShow,
  onError,
  onClickAd,
  extraLogCallTime = 0,
  isInView,
}: TProps) => {
  const [bannerData, setBannerData] = useState<TAdsItem>();
  const {isAdTop, setIsAdTop, setTopAdItem, topAdItem} = useAdBanner();

  const refInitLoad = useRef(false);
  const wrapRef = useRef<HTMLDivElement>(null);

  const {userInfo, isPortrait, nowCenter, nowCenterPosTime, sessionId, adsTickKey} = useAppSelector(
    (state) => ({
      userInfo: state.userInfo,
      isPortrait: !state.layout.appSize.isLandscape,
      nowCenter: state.map.nowCenter,
      nowCenterPosTime: state.map.nowCenterPosTime,
      sessionId: state.userInfo.sessionId,
      adsTickKey: state.tplacehome.adsTickKey,
    })
  );
  const nowBannerLonLat = bannerLonLat || nowCenter;
  const {originQueries} = useParseQueryLocation();

  const requestSetting: TRequestSetting = useMemo(() => {
    const originWidth = isPortrait ? window.innerWidth : window.innerHeight;
    const originHeight = isPortrait ? window.innerHeight : window.innerWidth;

    return {
      env: APP_SERVER_NAME,
      osType: ua.isInApp ? (ua.isAndroid ? 'AND' : 'IOS') : 'ETC',
      osVersion: ua.osVersion,
      appVersion: ua.tmapAppVersion || `${originQueries.tak || ''}` || '99.99.99',
      appCode: 'tmap',
      deviceId: userInfo.device.deviceId,
      carrier: userInfo.device.carrierName,
      userKey: userInfo.userKey,
      inventoryCode,
      lmt: userInfo.adId ? 1 : 0,
      adid: ua.isInApp && ua.isAndroid ? userInfo.adId : TEMP_ADID,
      idfa: ua.isInApp && ua.isIos ? userInfo.adId : '',
      idfv: ua.isInApp && ua.isIos ? userInfo.deviceServiceVendorId : '',
      language: navigator.language,
      deviceWidth: originWidth,
      deviceHeight: originHeight,
      orientation: isPortrait ? 'portrait' : 'landscape',
      sessionId,
    };
  }, [
    isPortrait,
    originQueries.tak,
    userInfo.device.deviceId,
    userInfo.device.carrierName,
    userInfo.userKey,
    userInfo.adId,
    userInfo.deviceServiceVendorId,
    inventoryCode,
    sessionId,
  ]);

  const {getTmapAds, callCompleteLog} = useTMapAds(requestSetting);

  const getAd = useCallback(() => {
    // isAdTop 인 경우 바로 데이터 설정 (adBannerContext)
    if (isAdTop && topAdItem) {
      setBannerData(topAdItem);
      return;
    }

    const requestParam: TRequestVariableParams = {
      keyword: getFirstParam(originQueries.searchQuery),
      lon: `${nowBannerLonLat?.lon || ''}`,
      lat: `${nowBannerLonLat?.lat || ''}`,
      posTime: getApiTime(nowCenterPosTime),
    };
    onStart?.({
      ...requestSetting,
      ...requestParam,
    });

    return getTmapAds(requestParam)
      .then((ads: TAdsItem[]) => {
        // isAdTop 인경우 저장 후 top 설정 (adBannerContext)
        if (ads[0]?.isTopFixed) {
          setTopAdItem(ads[0]);
          setIsAdTop(true);
        } else {
          setBannerData(shuffle(ads || [])[0]);
        }
      })
      .catch((error) => {
        setIsAdTop(false);
        onError?.(error);
      });
  }, [
    getTmapAds,
    nowBannerLonLat?.lat,
    nowBannerLonLat?.lon,
    nowCenterPosTime,
    onError,
    onStart,
    originQueries.searchQuery,
    requestSetting,
    setIsAdTop,
    isAdTop,
    setTopAdItem,
    topAdItem,
  ]);

  const handleClickBanner = useCallback(() => {
    bannerData && onClickAd?.(bannerData.landingUrl || '', bannerData);

    bannerData && callCompleteLog(bannerData.logs.click);

    const {serviceName, serviceData} = parseDeepLink(bannerData?.landingUrl);

    // callCompleteLog datadog api 전송시간을 고려하여 setTimeout
    setTimeout(() => {
      if (serviceName) {
        TMapSender.openServiceByName(serviceName as TServiceName, serviceData);
      } else {
        bannerData?.landingUrl && TMapSender.openBrowser(bannerData.landingUrl);
      }
    }, 500);
  }, [bannerData, callCompleteLog, onClickAd]);

  const handleLoadBanner = useCallback(() => {
    bannerData && onShow?.(bannerData);
    refInitLoad.current = true;
  }, [bannerData, onShow]);

  const handleErrorBanner = useCallback(() => {
    onError?.(new Error(EAdsErrorMessage.RESOURCE_LOAD_FAIL));
    refInitLoad.current = true;
  }, [onError]);

  useOnce(nowBannerLonLat?.lat && nowBannerLonLat?.lon, () => {
    getAd();
  });

  const timerRef = useRef<ReturnType<typeof window.setTimeout>>();
  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      if (isInView && bannerData && bannerData.logs.vimp) {
        // 상위엘리먼트에서 ignore 체크
        const ignoreEl = wrapRef.current?.closest(
          '[data-ignore-tmap-ad-impression]'
        ) as HTMLElement;
        if (ignoreEl && ignoreEl.dataset.ignoreTmapAdImpression === 'true') {
          return;
        }
        callCompleteLog(bannerData.logs.vimp, {updateId: true});
      }
    }, 100);
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [bannerData, callCompleteLog, isInView, adsTickKey]);

  return inventoryCode === ETMapBannerCode.RANKING_GO_WHERE ? (
    bannerData ? (
      <div className={s.ranking_banner_wrap}>
        <RankingBanner data={bannerData} onClick={handleClickBanner} onShow={callCompleteLog} />
      </div>
    ) : null
  ) : bannerData ? (
    <div className={s.internal_banner} ref={wrapRef}>
      <img
        className={s.banner_image}
        src={bannerData.imageUrl}
        alt="광고 배너"
        onLoad={handleLoadBanner}
        onError={handleErrorBanner}
        onClick={handleClickBanner}
      />
    </div>
  ) : null;
};

export default TMapInternalBanner;
