import {useAppSelector, useAppDispatch} from 'ducks/hooks';
import {useCallback, useEffect, useMemo, useState} from 'react';
import DiscoveryColumnPoiItem from './DiscoveryColumnPoiItem';
import classNames from 'classnames';
import DiscoverySection from './DiscoverySection';
import {ReactComponent as ImgBag} from 'resource/images/img_luggage.svg';
import {IcArrowRight} from '../../@tmds/icons/v1.2/IcArrowRight';
import {isEmpty, shuffle} from '../../../utils/lodash';

import s from '../../../styles/components/tplacehome/TPlaceDiscovery.module.scss';
import actions from 'ducks/actions';
import {fetchTripData, fetchRegionListDepth2, fetchRegionListDepth1} from 'ducks/tplacehome/slice';
import useDimmedQuery from 'hooks/useDimmedQuery';
import RegionMainPopup from './popups/RegionMainPopup';
import CustomSelectLayer from 'components/CustomSelectLayer';
import DiscoverySkeleton from './DiscoverySkeleton';
import InView from 'react-intersection-observer';
import usePlaceHome from 'hooks/usePlaceHome';
import useFavorite from 'hooks/useFavorite';
import {EImageSize} from 'types/Image';

const DiscoveryTrip = (props: {logParam: any}) => {
  const dispatch = useAppDispatch();
  const {tplacehome, userInfo} = useAppSelector((state) => state);
  const placehomeHook = usePlaceHome();
  const {selectedRegionNameInfo, userRegionInfo} = useMemo(
    () => tplacehome.discoveryData,
    [tplacehome.discoveryData]
  );

  const listData = useMemo(
    () => Object.values(tplacehome.discoveryData.result.data.tripData).flat(),
    [tplacehome.discoveryData.result.data.tripData]
  );

  const {checkIsFavorite} = useFavorite({list: listData});

  const [itemSelectProcessing, setItemSelectProcessing] = useState(false);
  const [userSelectedName1, setUserSelectedName1] = useState(
    selectedRegionNameInfo.depth1 || userRegionInfo?.foundAreaInfo.defaultRegion1Name || ''
  );
  const [userSelectedName2, setUserSelectedName2] = useState(selectedRegionNameInfo.depth2 || '');

  const regionMainPopupQuery = useDimmedQuery({id: 'REGION_MAIN_POPUP'});
  const regionItemPopupQuery1 = useDimmedQuery({id: 'REGION_SELECT_1'});
  const regionItemPopupQuery2 = useDimmedQuery({id: 'REGION_SELECT_2'});

  const storeNameInfo = useMemo(() => {
    return {
      depth1:
        selectedRegionNameInfo.depth1 || userRegionInfo?.foundAreaInfo.defaultRegion1Name || '',
      depth2: selectedRegionNameInfo.depth2 || '',
    };
  }, [
    selectedRegionNameInfo.depth1,
    selectedRegionNameInfo.depth2,
    userRegionInfo?.foundAreaInfo.defaultRegion1Name,
  ]);

  /**
   * utils
   */
  const resetUserInputValues = useCallback(() => {
    setUserSelectedName1(storeNameInfo.depth1);
    setUserSelectedName2(storeNameInfo.depth2);
  }, [storeNameInfo.depth1, storeNameInfo.depth2]);

  const getDepth1DataByName = useCallback(
    (name: string) => tplacehome.regionList.depth1?.find((item) => item.areaName === name),
    [tplacehome.regionList.depth1]
  );

  /**
   * computed
   */
  const itemOptionList1 = useMemo(
    () =>
      tplacehome.regionList.depth1 &&
      tplacehome.regionList.depth1.map((item) => ({
        id: item.areaName,
        label: item.areaName,
      })),
    [tplacehome.regionList.depth1]
  );
  const itemOptionList2 = useMemo(() => {
    const depth1Data = getDepth1DataByName(userSelectedName1);
    if (depth1Data) {
      const targetList = tplacehome.regionList.depth2[depth1Data.areaId] || [];
      return targetList.map((item) => ({
        id: item.areaName,
        label: item.areaName,
      }));
    }
  }, [getDepth1DataByName, userSelectedName1, tplacehome.regionList.depth2]);

  const has2Depth = useMemo(() => isEmpty(itemOptionList2), [itemOptionList2]);

  const selectedOptionItem1 = useMemo(() => {
    return itemOptionList1?.find((item) => item.label === userSelectedName1);
  }, [itemOptionList1, userSelectedName1]);
  const selectedOptionItem2 = useMemo(() => {
    return itemOptionList2?.find((item) => item.label === userSelectedName2);
  }, [itemOptionList2, userSelectedName2]);

  const secondRegionName = useMemo(() => {
    if (!storeNameInfo.depth1) {
      return;
    }
    const regions = [
      ['서울', '인천', '경기', '강원'],
      ['경남', '경북', '대구', '울산', '부산'],
      ['대전', '세종', '충북', '충남'],
      ['광주', '전남', '전북'],
      ['제주'],
    ];
    const findIndex = regions.findIndex((group) => group.some((v) => v === storeNameInfo.depth1));
    const result = regions[findIndex].filter((v) => v !== storeNameInfo.depth1);
    return shuffle(result)[0];
  }, [storeNameInfo.depth1]);

  const keys = useMemo(() => {
    return Object.keys(tplacehome.discoveryData.result.data.tripData);
  }, [tplacehome]);

  const isNoShow = useMemo(() => {
    const arr = keys.map((key) => {
      const data = tplacehome.discoveryData.result.data.tripData[key];
      if (data) {
        return data.length as number;
      }
      return 0;
    });
    return Math.max(...arr) <= 2;
  }, [keys, tplacehome]);

  const getCategoryName = useCallback((key: string) => {
    switch (key) {
      case 'food':
        return '맛집';
      case 'landmark':
        return '명소';
      case 'cafe':
        return '카페';
      case 'bar':
        return '술집';
      case 'hotel':
        return '숙소';
    }
  }, []);

  /**
   * handlers
   */
  const handleOpenRegionSelect = useCallback(() => {
    placehomeHook.sendEventDiscovery('tap.travel_region');
    resetUserInputValues();
    regionMainPopupQuery.open();
  }, [placehomeHook, regionMainPopupQuery, resetUserInputValues]);

  const handleRegionReset = useCallback(async () => {
    const userPositionDepth1Name = userRegionInfo?.foundAreaInfo.defaultRegion1Name || '';
    setUserSelectedName1(userPositionDepth1Name);
    setUserSelectedName2('');
  }, [userRegionInfo?.foundAreaInfo.defaultRegion1Name]);

  const handleRegionSubmit = useCallback(() => {
    regionMainPopupQuery.close();
    dispatch(
      actions.tplacehome.setDiscoveryTripRegionName({
        depth1: userSelectedName1,
        depth2: userSelectedName2,
      })
    );
    dispatch(fetchTripData({}));
  }, [dispatch, regionMainPopupQuery, userSelectedName1, userSelectedName2]);

  const handleMainDepthClick = useCallback(
    (depth: 1 | 2) => {
      setItemSelectProcessing(true);
      regionMainPopupQuery.close();
      // 지역선택 닫고 > url변경 > 아이템선택
      setTimeout(() => {
        if (depth === 1) {
          regionItemPopupQuery1.open();
        } else if (depth === 2) {
          regionItemPopupQuery2.open();
        }
      }, 1);
    },
    [regionMainPopupQuery, regionItemPopupQuery1, regionItemPopupQuery2]
  );

  const handleDepth1Item = useCallback(
    (optionItem) => {
      setUserSelectedName1(optionItem.id);
      setUserSelectedName2('');
      regionItemPopupQuery1.close();
    },
    [regionItemPopupQuery1]
  );

  const handleDepth2Item = useCallback(
    (optionItem) => {
      setUserSelectedName2(optionItem.id);
      regionItemPopupQuery2.close();
    },
    [regionItemPopupQuery2]
  );

  const handleSecondRegion = useCallback(async () => {
    placehomeHook.sendEventDiscovery('tap.travel_region_recommend');
    await dispatch(
      actions.tplacehome.setDiscoveryTripRegionName({
        depth1:
          secondRegionName ||
          tplacehome.discoveryData.userRegionInfo?.foundAreaInfo.defaultRegion1Name ||
          '',
        depth2: '',
      })
    );
    dispatch(fetchTripData({}));
  }, [
    dispatch,
    placehomeHook,
    secondRegionName,
    tplacehome.discoveryData.userRegionInfo?.foundAreaInfo.defaultRegion1Name,
  ]);

  /**
   * hooks
   */
  // 아이템 선택 팝업이 닫히면, 지역선택을 다시 연다.
  useEffect(() => {
    if (itemSelectProcessing && !regionMainPopupQuery.isOpen) {
      regionMainPopupQuery.open();
      setItemSelectProcessing(false);
    }
  }, [itemSelectProcessing, regionMainPopupQuery]);

  // 뎁스1이 변경되고 뎁스2데이터가 없으면 뎁스2 데이터를 가져온다.
  useEffect(() => {
    const depth1Data = getDepth1DataByName(userSelectedName1);
    const depth2Data = depth1Data && tplacehome.regionList.depth2[depth1Data.areaId];
    if (depth1Data && !depth2Data) {
      dispatch(fetchRegionListDepth2(depth1Data?.areaId || ''));
    }
  }, [dispatch, getDepth1DataByName, tplacehome.regionList.depth2, userSelectedName1]);

  // depth1 리스트 정보가 없으면 로드
  useEffect(() => {
    const loadDepth1 = async () => {
      await dispatch(fetchRegionListDepth1({}));
    };
    if (!tplacehome.regionList.depth1) {
      loadDepth1();
    }
  }, [dispatch, tplacehome.regionList.depth1]);

  if (isNoShow) {
    return null;
  }

  return (
    <>
      <DiscoverySection
        mainTitle={'인기 여행지'}
        subTitle={'여행 어디로 갈까?'}
        isSelector
        noFirstLetterHighlight
        handleClick={handleOpenRegionSelect}
        name="discovery_trip"
      >
        {tplacehome.discoveryData.tripLoading ? (
          <DiscoverySkeleton marginTop="8px" />
        ) : (
          <InView
            onChange={(isView) =>
              isView && placehomeHook.sendEventDiscovery('view.travel_poi', props.logParam)
            }
          >
            <div className={s.course}>
              <ul className={s.list}>
                {keys.map((key, index) => {
                  const data = tplacehome.discoveryData.result.data.tripData[key];

                  if (!data || data.length <= 2) {
                    return null;
                  }
                  return (
                    <li key={index} className={s.item}>
                      <div className={s.label}>
                        <span
                          className={classNames(s.text, {
                            [s.place]: key === 'landmark' || key === 'hotel',
                          })}
                        >
                          {getCategoryName(key)}
                        </span>
                      </div>
                      <ul className={s.carousel_list} data-flicking-ignore>
                        {data.map((item, idx) => {
                          return (
                            <li key={idx} className={s.cell}>
                              <DiscoveryColumnPoiItem
                                data={item}
                                thumbHeight={100}
                                thumbnailSize={EImageSize.THUMBNAIL}
                                isLowestPrice={key === 'hotel'}
                                onClickDetail={() =>
                                  placehomeHook.sendEventDiscovery('tap.travel_poi', {
                                    euk: userInfo.euk,
                                    pkey: item.pkey,
                                    list_seq: idx + 1,
                                  })
                                }
                                isFavorite={checkIsFavorite(item)}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
              {storeNameInfo.depth1 !== '제주' && (
                <button className={s.recommend} onClick={handleSecondRegion}>
                  <div className={s.text}>
                    <ImgBag />
                    <p>
                      <em className={s.first}>{storeNameInfo.depth2 || storeNameInfo.depth1}</em>{' '}
                      다음으로 많이 간<br />
                      <em className={s.second}>{secondRegionName} 여행지</em>는 어디?
                    </p>
                  </div>
                  <IcArrowRight width={18} height={18} color={'gray500'} />
                </button>
              )}
            </div>
          </InView>
        )}
      </DiscoverySection>
      <RegionMainPopup
        isOpen={regionMainPopupQuery.isOpen}
        name1={userSelectedName1}
        name2={userSelectedName2}
        name2Disabled={has2Depth}
        onClickDepthButton={handleMainDepthClick}
        onClickReset={handleRegionReset}
        onClickSubmit={handleRegionSubmit}
      />
      <CustomSelectLayer
        isOpen={regionItemPopupQuery1.isOpen}
        options={itemOptionList1 || []}
        selectedItem={selectedOptionItem1}
        onClickItem={handleDepth1Item}
      />
      <CustomSelectLayer
        isOpen={regionItemPopupQuery2.isOpen}
        options={itemOptionList2 || []}
        selectedItem={selectedOptionItem2}
        onClickItem={handleDepth2Item}
      />
    </>
  );
};

export default DiscoveryTrip;
