import {useCallback} from 'react';
import {Link} from 'react-router-dom';
import useDimmedQuery from 'hooks/useDimmedQuery';
import useLogger from 'hooks/useLogger';
import useLocationSort, {LOCATION_SORT_LIST} from 'hooks/useLocationSort';
import {IcoArrowDown} from 'components/@tmds/icons/IcoArrowDown';
import CustomSelectLayer from 'components/CustomSelectLayer';
import {TSearchResultSortParam} from 'components/search/SearchResultHeader';

import s from 'styles/components/search/SearchResultSort.module.scss';

type TProps = {
  pageHide?: boolean;
  onChange?: (param: TSearchResultSortParam) => void;
};

const SearchResultSortBusStation = ({pageHide, onChange}: TProps) => {
  const locationDimmed = useDimmedQuery({filter: 'location'});
  const {sendClickLog} = useLogger();
  const {nowLocationItem, updateLocationItem} = useLocationSort();

  const handleClickLocationType = useCallback(() => {
    if (!nowLocationItem) {
      return;
    }

    sendClickLog('tap.search_location');
  }, [nowLocationItem, sendClickLog]);

  const handleClickLocationItem = useCallback(
    (item) => {
      const type = item.id;

      sendClickLog(item.actionId);
      onChange?.({locationType: type});
      updateLocationItem(type);
      locationDimmed.close();
    },
    [onChange, locationDimmed, updateLocationItem, sendClickLog]
  );

  return (
    <>
      <div className={s.sort_wrap}>
        <Link
          className={s.sort_item}
          to={locationDimmed.openLocation}
          role="button"
          onClick={handleClickLocationType}
          aria-label={`현재 ${nowLocationItem!.label} 정렬결과입니다. 정렬결과 변경하기`}
          draggable={false}
        >
          <span className={s.label}>{nowLocationItem?.label}</span>
          <IcoArrowDown width={12} height={12} color="gray700" />
        </Link>
      </div>
      {!pageHide && (
        <CustomSelectLayer
          isOpen={locationDimmed.isOpen}
          options={LOCATION_SORT_LIST}
          selectedItem={nowLocationItem}
          onClickItem={handleClickLocationItem}
        />
      )}
    </>
  );
};

export default SearchResultSortBusStation;
