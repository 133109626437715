import classNames from 'classnames';
import actions from 'ducks/actions';

import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {ETPlaceTab} from 'ducks/tplacehome/types';
import {useOnce} from 'hooks/useOnce';
import usePlaceHome from 'hooks/usePlaceHome';
import DiscoveryTabIcon from 'resource/images/ico_placehome_discovery_tab.svg';

import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import s from 'styles/components/tplacehome/TPlaceTab.module.scss';
import useRedDot from 'hooks/useRedDot';
import {ERedDotItemGroup} from '@lcc/tmap-inapp';

const RED_DOT_ITEM_NAME = 'TAB_DISCOVER';

const TPlaceTab = () => {
  const {tplacehome, layout} = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const buttonWrapRef = useRef<HTMLDivElement>(null);
  const [inited, setInited] = useState(false);
  const placehomeHook = usePlaceHome();
  const {getRedDotItem, updateRedDotList} = useRedDot({
    itemGroup: ERedDotItemGroup.PLACE,
  });

  const handleClickTab = useCallback(
    (value) => {
      placehomeHook.sendEvent(
        value === ETPlaceTab.AROUND_ME ? 'tap.tab_around' : 'tap.tab_discover'
      );
      dispatch(actions.tplacehome.setCurrentTab(value));
    },
    [dispatch, placehomeHook]
  );

  const bgStyle = useMemo(() => {
    if (!inited) {
      return {};
    }
    const buttonWrapEl = buttonWrapRef.current;
    const left = tplacehome.currentTab === ETPlaceTab.AROUND_ME ? 'calc(0% + 3px)' : '50%';
    if (buttonWrapEl) {
      return {
        left,
        opacity: 1,
      };
    } else {
      return {
        left: '0',
        opacity: 1,
      };
    }
  }, [inited, tplacehome.currentTab]);

  const hasRedDot = useMemo(() => {
    return getRedDotItem(RED_DOT_ITEM_NAME);
  }, [getRedDotItem]);

  useEffect(() => {
    if (tplacehome.currentTab === ETPlaceTab.DISCOVERY && hasRedDot) {
      updateRedDotList(RED_DOT_ITEM_NAME);
    }
  }, [hasRedDot, tplacehome.currentTab, updateRedDotList]);

  useOnce(
    tplacehome.aroundMeData.result.loaded ||
      tplacehome.aroundMeData.result.error ||
      tplacehome.discoveryData.result.loaded ||
      tplacehome.discoveryData.result.error,
    () => {
      setInited(true);
    }
  );

  return (
    <div className={s.wrap}>
      {!layout.appSize.isLandscape && (
        <div className={s.handle_wrap}>
          <div className={s.handle} />
        </div>
      )}

      <div className={s.button_wrap} ref={buttonWrapRef}>
        {Object.values(ETPlaceTab).map((v, index) => (
          <button
            key={v}
            data-name={v}
            className={classNames(s.button, {
              [s.is_selected]: inited && tplacehome.currentTab === v,
            })}
            onClick={() => handleClickTab(v)}
          >
            {v === ETPlaceTab.AROUND_ME ? (
              '내 주변'
            ) : (
              <>
                <img src={DiscoveryTabIcon} alt="" style={{marginRight: '1px'}} />
                <span>발견</span>
                {hasRedDot && (
                  <span className={s.red_dot_wrap}>
                    <i className={s.red_dot_point} />
                  </span>
                )}
              </>
            )}
          </button>
        ))}
        <div className={s.selected_bg} style={bgStyle} />
      </div>
    </div>
  );
};

export default TPlaceTab;
