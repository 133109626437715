import {isProdEnv, isStageEnv} from 'constant/Env';
import {getRedirectFromInParam} from 'utils/tmapUtils';

export type TRemoteConfigScope =
  | 'search_category_link_prod'
  | 'custom_resources_for_place_service_prod'
  | 'tnow_category_link_prod'
  | 'search_category_link_dev'
  | 'custom_resources_for_place_service_dev'
  | 'tnow_category_link_dev'
  | 'tnow_popup_prod'
  | 'tnow_popup_dev'
  | 'category_ev_filter_dev'
  | 'category_ev_filter_prod'
  | 'search_recommend_keyword'
  | 'search_recommend_keyword_dev'
  | 'tplacehome_contents_dev'
  | 'tplacehome_contents_prod'
  | 'search_tooltip_dev'
  | 'search_tooltip_prod'
  | 'search_placeholders_dev'
  | 'search_placeholders'
  | 'save_maplevel_clustering'
  | 'save_maplevel_clustering_dev'
  | 'save_maplevel_clustering_stg'
  | 'tplacehome_ui_ab_test_prod'
  | 'tplacehome_ui_ab_test_dev';

export type TRemoteConfigKeys =
  | 'THEME_MARKER'
  | 'CUSTOM_CATEGORY_PAGE'
  | 'SEARCH_MAIN_CATEGORY_LINK'
  | 'SEARCH_RESULT_CATEGORY_LINK'
  | 'TNOW_CATEGORY_LINK'
  | 'TNOW_POPUP_VERSION'
  | 'TNOW_POPUP_LIST'
  | 'EV_FILTER_LIST'
  | 'SEARCH_RECOMMEND_KEYWORD'
  | 'TPLACEHOME_SETTINGS'
  | 'TOOLTIP_LIST'
  | ESearchPlaceHolder.TNOW_PLACEHOLDER
  | ESaveMapLevelClustering.CLUSTER
  | ESaveMapLevelClustering.MIN_FAV_COUNT
  | 'TPLACEHOME_UI_AB';

// firebase search_placeholder로 사용되는 값은 배열로 내려옴 (기존 사용했던 파이어베이스 값과 다름)
export enum ESearchPlaceHolder {
  TNOW_PLACEHOLDER = 6,
}

export enum ESaveMapLevelClustering {
  CLUSTER = 'cluster',
  MIN_FAV_COUNT = 'minFavCount',
}

export type TPageConfig = {
  scope: TRemoteConfigScope;
  keys: TRemoteConfigKeys[];
}[];

const {isFromProdInRtg} = getRedirectFromInParam();
const isProdConfig = isFromProdInRtg || isProdEnv;

const MAP_LEVEL_CONFIG = {
  scope: (isProdConfig
    ? 'save_maplevel_clustering'
    : isStageEnv
    ? 'save_maplevel_clustering_stg'
    : 'save_maplevel_clustering_dev') as TRemoteConfigScope,
  keys: [ESaveMapLevelClustering.CLUSTER, ESaveMapLevelClustering.MIN_FAV_COUNT],
};

export const SEARCH_CONFIG: TPageConfig = [
  {
    scope: isProdConfig ? 'search_category_link_prod' : 'search_category_link_dev',
    keys: ['SEARCH_MAIN_CATEGORY_LINK', 'SEARCH_RESULT_CATEGORY_LINK'],
  },
  {
    scope: isProdConfig
      ? 'custom_resources_for_place_service_prod'
      : 'custom_resources_for_place_service_dev',
    keys: ['THEME_MARKER', 'CUSTOM_CATEGORY_PAGE'],
  },
  {
    scope: isProdConfig ? 'search_recommend_keyword' : 'search_recommend_keyword_dev',
    keys: ['SEARCH_RECOMMEND_KEYWORD'],
  },
  {
    scope: isProdConfig ? 'search_tooltip_prod' : 'search_tooltip_dev',
    keys: ['TOOLTIP_LIST'],
  },
  MAP_LEVEL_CONFIG,
];

export const PLACE_CONFIG: TPageConfig = [
  {
    scope: isProdConfig ? 'tnow_category_link_prod' : 'tnow_category_link_dev',
    keys: ['TNOW_CATEGORY_LINK'],
  },
  {
    scope: isProdConfig
      ? 'custom_resources_for_place_service_prod'
      : 'custom_resources_for_place_service_dev',
    keys: ['THEME_MARKER', 'CUSTOM_CATEGORY_PAGE'],
  },
  {
    scope: isProdEnv ? 'tnow_popup_prod' : 'tnow_popup_dev',
    keys: ['TNOW_POPUP_VERSION', 'TNOW_POPUP_LIST'],
  },
  {
    scope: isProdConfig ? 'category_ev_filter_prod' : 'category_ev_filter_dev',
    keys: ['EV_FILTER_LIST'],
  },
  {
    scope: isProdConfig ? 'tplacehome_contents_prod' : 'tplacehome_contents_dev',
    keys: ['TPLACEHOME_SETTINGS'],
  },
  {
    scope: isProdConfig ? 'search_placeholders' : 'search_placeholders_dev',
    keys: [ESearchPlaceHolder.TNOW_PLACEHOLDER],
  },
  {
    scope: isProdConfig ? 'tplacehome_ui_ab_test_prod' : 'tplacehome_ui_ab_test_dev',
    keys: [],
  },
  MAP_LEVEL_CONFIG,
];

export const FIRE_BASE_WEBAPP_CONFIG = {
  apiKey: 'AIzaSyASeTN_1BEmJfEAXXIb887F0kzwuzUU0fM',
  authDomain: 't-map-474e8.firebaseapp.com',
  databaseURL: 'https://t-map-474e8.firebaseio.com',
  projectId: 't-map-474e8',
  storageBucket: 't-map-474e8.appspot.com',
  messagingSenderId: '272139553947',
  appId: '1:272139553947:web:947432ec58d39a2529ca3e',
  measurementId: 'G-CC0CSZ32JQ',
};
