import {HTMLAttributes, PropsWithChildren, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import TMapSender from '@lcc/tmap-inapp';
import ua from 'utils/uaParser';

type TProps = PropsWithChildren<HTMLAttributes<HTMLAnchorElement>> & {
  onGoBack?: VoidFunction;
  onClick?: VoidFunction;
};

export const HistoryBackLink = ({children, onClick, onGoBack, ...anchorProps}: TProps) => {
  const history = useHistory();
  const handleClickClose = useCallback(
    (e) => {
      e.preventDefault();
      onClick?.();

      if (onGoBack) {
        onGoBack();
        return;
      }

      if (ua.isInApp) {
        TMapSender.onBackKeyPressed();
      } else {
        history.goBack();
      }
    },
    [history, onGoBack, onClick]
  );

  return (
    <a {...anchorProps} href="#back" onClick={handleClickClose} draggable={false}>
      {children}
    </a>
  );
};
