import {TTPlaceCurationType3} from 'ducks/remote/type';
import {moveByLandingType} from 'utils/url';
import usePlaceHome from 'hooks/usePlaceHome';
import Thumbnail from 'components/Thumbnail';

import s from 'styles/components/tplacehome/TPlaceCurationType3.module.scss';

type TProps = {
  item: TTPlaceCurationType3;
  contentIndex: number;
};

const TPlaceCurationType3 = ({item, contentIndex}: TProps) => {
  const placehomeHook = usePlaceHome();
  return (
    <div className={s.wrap}>
      <h4 dangerouslySetInnerHTML={{__html: item.title}} />
      {item.list.length > 0 && (
        <ul className={s.list} data-flicking-ignore="true">
          {item.list.map((v, i) => (
            <li
              key={i}
              className={s.item}
              onClick={() => {
                placehomeHook.sendCurrationTapEvent(item.type, item.title, i, contentIndex);
                moveByLandingType(v.landingType, v.landingUrl);
              }}
            >
              <Thumbnail
                imgSrc={v.thumbnailUrl}
                alt={'이미지'}
                className={s.image}
                width={230}
                height={329}
                borderColor="none"
              />
            </li>
          ))}
          {/* ios에서 margin을 스크롤영역으로 인식하지 않음. 여백을 주기위함 */}
          <li className={s.spacer} />
        </ul>
      )}
    </div>
  );
};

export default TPlaceCurationType3;
