import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {useCallback, useEffect, useMemo, useRef, useState, Fragment} from 'react';

import ContentWrap from '../shared/ContentWrap';
import actions from 'ducks/actions';
import {TPlaceContentItem} from 'ducks/remote/type';
import {fetchAroundMeList} from 'ducks/tplacehome/slice';
import {EAdditionalInfo, ETPlaceTab, ECategoryType} from 'ducks/tplacehome/types';
import {debounce, isEmpty} from 'utils/lodash';
import {useOnce} from 'hooks/useOnce';
import InView from 'react-intersection-observer';
import AroundMeItem from './AroundMeItem';

import {EBusinessHourStatus} from 'types/App';
import AroundMeHeader from './AroundMeHeader';
import ContentError from '../shared/ContentError';
import AroundMeEmpty from './AroundMeEmpty';
import AroundMeSkeleton from './AroundMeSkeleton';

import s from 'styles/components/tplacehome/TPlaceAroundMe.module.scss';
import DiscoveryRegionList from '../discovery/DiscoveryRegionList';
import RankingStaticBanner from '../shared/RankingStaticBanner';
import {
  EAdCode,
  ENaverDaUnitId,
  isBannerTestEnv,
  PLACE_BANNER_AD_STEP,
  PLACE_BANNER_PROVIDER_CONFIG,
  PLACE_BANNER_SECOND_PROVIDER_CONFIG,
} from 'constant/Ads';
import usePlaceHome from 'hooks/usePlaceHome';
import cloneDeep from 'lodash/cloneDeep';
import TPlaceCuration from '../TPlaceCuration';
import TPlaceAdBanner from '../shared/TPlaceAdBanner';
import useFavorite from 'hooks/useFavorite';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import {remoteDefaultData} from 'ducks/remote/defaultData';

const AroundMe = () => {
  const {tplacehome, userInteraction, remote} = useAppSelector((state) => state);
  const filteredList = useMemo(
    () => tplacehome.aroundMeData.result.data.filteredList,
    [tplacehome.aroundMeData.result.data.filteredList]
  );
  const firstDataLoaded = useRef(false);
  const dispatch = useAppDispatch();
  const placehomeHook = usePlaceHome();
  const {checkIsFavorite} = useFavorite({list: filteredList});
  const {tailParam} = useParseQueryLocation();

  const [contentScrollTop, setContentScrollTop] = useState(-1);
  const [contentScrollTopKey, setContentScrollTopKey] = useState(-1);

  /**
   * 콘텐츠 스크롤에 다른 activePoi 지정 (지도 마커 활성화)
   */
  const visibleItemList = useRef<(boolean | string)[]>([]);
  const activeId = useMemo(() => {
    if (userInteraction.activePoi && ['marker', 'rotate'].includes(userInteraction.trigger || '')) {
      return userInteraction.activePoi;
    }
    return undefined;
  }, [userInteraction]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateFocusItem = useCallback(
    debounce(() => {
      const idx = visibleItemList.current.findIndex((visible) => visible === true);
      const item = tplacehome.aroundMeData.result.data.filteredList[idx];
      if (item) {
        dispatch(
          actions.userInteraction.setInteraction({
            activePoi: item.listId,
            trigger: 'scroll',
          })
        );
      }
    }, 300),
    [tplacehome.aroundMeData.result.data.filteredList]
  );
  const onChangeViewingItems = useCallback(
    (i, visible) => {
      visibleItemList.current[i] = visible;
      updateFocusItem();
    },
    [updateFocusItem]
  );

  /**
   * 지도에서 마커 탭시 콘텐츠 스크롤
   */
  const moveContentScroll = useCallback((value: number) => {
    setContentScrollTop(value);
    setContentScrollTopKey(Date.now());
  }, []);
  useEffect(() => {
    if (activeId) {
      const position =
        document.querySelector<HTMLDivElement>(`[data-id="${activeId}"]`)?.offsetTop || 0;
      moveContentScroll(position);
    }
  }, [activeId, moveContentScroll]);
  useEffect(() => {
    moveContentScroll(0);
  }, [moveContentScroll, tplacehome.aroundMeData.result.data.list]);

  /**
   * 새로 데이터를 불러오거나, 필터가 선택됐을때 실제 보여지는 리스트 갱신 (filteredList)
   */
  useEffect(() => {
    const {additionalInfos} = tplacehome.aroundMeData.selectedFilters;
    const sortByDistance = tplacehome.aroundMeData.sortByDistance;
    const sortByScore = tplacehome.aroundMeData.sortByScore;
    let result = cloneDeep(tplacehome.aroundMeData.result.data.list);
    if (!result) {
      return;
    }

    const hasFilters = !isEmpty(additionalInfos);

    if (hasFilters) {
      result = result.filter((item) => {
        // 부가정보 - 하나라도 해당되지 않으면 열외
        const checkOpened = additionalInfos.includes(EAdditionalInfo.OPENED);
        const checkReservation =
          additionalInfos.includes(EAdditionalInfo.RESERVATION) &&
          placehomeHook.isReservationAvailableRegion;
        const checkParking = additionalInfos.includes(EAdditionalInfo.PARKING);

        if (checkOpened && item.special.businessHourStatus !== EBusinessHourStatus.OPEN) {
          return false;
        }
        if (
          checkReservation &&
          !(
            item.special.catchTableWaitingResponse &&
            (item.tag.isTmapReservation || item.tag.isTmapWaiting)
          )
        ) {
          return false;
        }
        if (checkParking && !(item.tag.isParking || item.tag.isValetParking)) {
          return false;
        }

        return true;
      });
    }

    if (sortByDistance) {
      // readonly?
      result.sort((a, b) => {
        if (a.distance < b.distance) {
          return -1;
        } else {
          return 1;
        }
      });
    }

    if (sortByScore) {
      result.sort((a, b) => {
        if (a.headingForScore > b.headingForScore) {
          return -1;
        } else {
          return 1;
        }
      });
    }

    dispatch(actions.tplacehome.setAroundMeFilteredList(result));
  }, [
    dispatch,
    tplacehome.aroundMeData.result.data.list,
    tplacehome.aroundMeData.selectedFilters,
    tplacehome.aroundMeData.sortByDistance,
    tplacehome.aroundMeData.sortByScore,
    placehomeHook.isReservationAvailableRegion,
  ]);

  /**
   * initial load
   */
  useOnce(
    tplacehome.initialDataLoaded && tplacehome.currentTab === ETPlaceTab.AROUND_ME,
    async () => {
      await dispatch(fetchAroundMeList({}));
    }
  );

  /**
   * 선택된 업종 변경시 api 재호출
   * 업종은 featureOr API param으로 필터링
   */
  useEffect(() => {
    if (firstDataLoaded.current) {
      dispatch(fetchAroundMeList({}));
    }
  }, [dispatch, tplacehome.aroundMeData.selectedFilters.categoryGroups]);

  /**
   * logs
   */
  useOnce(tplacehome.aroundMeData.result.loaded, () => {
    firstDataLoaded.current = true;
  });
  useEffect(() => {
    if (firstDataLoaded.current) {
      placehomeHook.sendEventAroundMe('view.around_poi', {
        list_num: tplacehome.aroundMeData.result.data.list.length,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tplacehome.aroundMeData.result.data.list]);

  useOnce(!!tailParam?.filter, () => {
    const filter = tailParam?.filter?.split(',').map((f) => f.trim()) || [];
    const additionalInfos: EAdditionalInfo[] = [];
    const categoryGroups: ECategoryType[] = [];

    const additionalInfovalues = Object.values(EAdditionalInfo);
    const categoryTypevalues = Object.values(ECategoryType);

    filter.forEach((f) => {
      const filterKey = f.toUpperCase();

      if (additionalInfovalues.includes(filterKey)) {
        additionalInfos.push(filterKey);
      }

      if (categoryTypevalues.includes(filterKey)) {
        categoryGroups.push(filterKey);
      }
    });

    dispatch(actions.tplacehome.setAroundMeSelectedFilters({additionalInfos, categoryGroups}));
  });

  /********************************************
   * render
   */
  const aroundContentList = useMemo(
    () =>
      remote.tplaceSettings?.aroundContentList ||
      remoteDefaultData.tplaceSettings.aroundContentList,
    [remote.tplaceSettings?.aroundContentList]
  );
  const curationContentList = useMemo(
    () =>
      remote.tplaceSettings?.curationContentList ||
      remoteDefaultData.tplaceSettings.curationContentList,
    [remote.tplaceSettings?.curationContentList]
  );
  const customContentMap = useMemo(() => {
    return aroundContentList.reduce((acc, cur) => {
      const order = cur.order || 0;
      if (!acc[order]) {
        acc[order] = [];
      }
      acc[order].push(cur);
      return acc;
    }, {} as {[key in number]: TPlaceContentItem[]});
  }, [aroundContentList]);

  const tailContentList = useMemo(() => {
    const result: TPlaceContentItem[] = [];
    if (filteredList.length && filteredList.length < 20) {
      result.push({type: 'RANKING_BANNER'});
    }
    aroundContentList.forEach((item) => {
      if (item.exposeRequired && (item.order || 0) >= filteredList.length) {
        result.push(item);
      }
    });
    return result;
  }, [filteredList.length, aroundContentList]);

  const getCustomContentComp = useCallback(
    (item: TPlaceContentItem, index: number) => {
      switch (item.type) {
        case 'AD_BANNER_01':
          return (
            <TPlaceAdBanner
              adOption={{
                ...PLACE_BANNER_PROVIDER_CONFIG,
                naver: {
                  adUnitId: isBannerTestEnv
                    ? ENaverDaUnitId.AROUND_1_TEST
                    : ENaverDaUnitId.PLACE_MAIN,
                },
              }}
              adStep={PLACE_BANNER_AD_STEP}
              adCode={EAdCode.PLACE_AROUND_ME_FIRST}
              isLogActive={tplacehome.currentTab === ETPlaceTab.AROUND_ME}
              logData={{custom: {order_no: index}}}
            />
          );
        case 'AD_BANNER_02':
          return (
            <TPlaceAdBanner
              adOption={PLACE_BANNER_SECOND_PROVIDER_CONFIG}
              adCode={EAdCode.PLACE_AROUND_ME_SECOND}
              isLogActive={tplacehome.currentTab === ETPlaceTab.AROUND_ME}
              logData={{custom: {order_no: index}}}
            />
          );
        case 'CURATION':
          const targetCurationItem = curationContentList.find(
            (curationItem) => curationItem.id === item.curationId
          );
          if (targetCurationItem) {
            return (
              <>
                <div className={s.spacer_gray} />
                <InView
                  onChange={(isView) =>
                    isView &&
                    placehomeHook.sendEventAroundMe('view.curating_contents', {
                      curating_content_type: targetCurationItem.type,
                      curating_content_title: targetCurationItem.title,
                      order_no: index,
                    })
                  }
                >
                  <TPlaceCuration item={targetCurationItem} contentIndex={index} />
                </InView>
                <div className={s.spacer_gray} />
              </>
            );
          }
          break;
        case 'RANKING':
          return (
            <>
              <div className={s.spacer_gray} />
              <InView
                onChange={(isView) =>
                  isView && placehomeHook.sendEventAroundMe('view.tmapranking', {order_no: index})
                }
              >
                <DiscoveryRegionList />
              </InView>
              <div className={s.spacer_gray} />
            </>
          );
        case 'RANKING_BANNER':
          return (
            <InView
              onChange={(isView) =>
                isView &&
                placehomeHook.sendEventAroundMe('view.tmapranking_banner', {order_no: index})
              }
              className={s.ranking_banner}
            >
              <RankingStaticBanner />
            </InView>
          );
      }
    },
    [curationContentList, placehomeHook, tplacehome.currentTab]
  );

  // 메인 콘텐츠 컴포넌트
  const BodyComp = useMemo(() => {
    if (tplacehome.aroundMeData.result.error) {
      return (
        <ContentError
          onClickRefresh={() => {
            placehomeHook.sendEventAroundMe('tap.fail_refresh');
            window.location.reload();
          }}
        />
      );
    }
    if (tplacehome.aroundMeData.result.loading) {
      return <AroundMeSkeleton />;
    }
    if (isEmpty(filteredList)) {
      return (
        <>
          <AroundMeEmpty />
          {getCustomContentComp({type: 'AD_BANNER_01'}, 0)}
        </>
      );
    }
    return (
      <>
        <ul className={s.content_list_wrap}>
          {filteredList.map((item, index) => (
            <Fragment key={index}>
              <li className={s.content_list_item} key={item.listId}>
                <InView
                  as="div"
                  data-type="poi"
                  threshold={0.5}
                  onChange={(visible) => onChangeViewingItems(index, visible)}
                >
                  <AroundMeItem item={item} itemIndex={index} isFavorite={checkIsFavorite(item)} />
                </InView>
              </li>
              {customContentMap?.[index]?.map((customContentItem) => (
                <Fragment key={index}>{getCustomContentComp(customContentItem, index)}</Fragment>
              ))}
            </Fragment>
          ))}
          {tailContentList?.map((customContentItem) =>
            getCustomContentComp(customContentItem, filteredList.length - 1)
          )}
        </ul>
      </>
    );
  }, [
    checkIsFavorite,
    customContentMap,
    filteredList,
    getCustomContentComp,
    onChangeViewingItems,
    placehomeHook,
    tailContentList,
    tplacehome.aroundMeData.result.error,
    tplacehome.aroundMeData.result.loading,
  ]);

  if (!tplacehome.initialDataLoaded) {
    return null;
  }
  return (
    <ContentWrap
      contentScrollTop={contentScrollTop}
      contentScrollTopKey={contentScrollTopKey}
      hideBtnTop={tplacehome.aroundMeData.result.loading}
    >
      <AroundMeHeader />
      {BodyComp}
    </ContentWrap>
  );
};

export default AroundMe;
